import React from 'react';
import { MessageSquare, BarChart3, BrainCircuit, ShieldCheck } from 'lucide-react';

function DemosPage() {
    return (
        <div className="max-w-6xl mx-auto px-4 py-8">
            <div className="text-center mb-16">
                <h1 className="heading-alt text-4xl md:text-5xl font-bold mb-6 text-[var(--color-azul)]">
                    Demos Interactivas
                </h1>
                <p className="text-xl text-gray-700">
                    Explora nuestras soluciones en acción y descubre el potencial de la transformación digital
                </p>
            </div>

            <div className="grid md:grid-cols-2 gap-8">
                <div className="border rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                    <div className="p-6 bg-gray-50">
                        <MessageSquare className="w-12 h-12 text-[var(--color-azul)] mb-4" />
                        <h2 className="text-2xl font-bold mb-3">Asistente AI Empresarial</h2>
                        <p className="text-gray-600 mb-4">
                            Chatbot avanzado que maneja consultas de clientes, gestiona ventas y
                            proporciona soporte técnico 24/7.
                        </p>
                        <ul className="text-sm text-gray-600 mb-6">
                            <li>• Respuestas personalizadas en tiempo real</li>
                            <li>• Integración con CRM y bases de conocimiento</li>
                            <li>• Escalamiento inteligente a agentes humanos</li>
                        </ul>
                        <button className="w-full bg-[var(--color-azul)] text-white py-2 px-4 rounded hover:opacity-90 transition-opacity">
                            Probar Demo
                        </button>
                    </div>
                </div>

                <div className="border rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                    <div className="p-6 bg-gray-50">
                        <BarChart3 className="w-12 h-12 text-[var(--color-azul)] mb-4" />
                        <h2 className="text-2xl font-bold mb-3">Dashboard Inteligente</h2>
                        <p className="text-gray-600 mb-4">
                            Panel de control en tiempo real con métricas clave y análisis predictivo
                            para toma de decisiones.
                        </p>
                        <ul className="text-sm text-gray-600 mb-6">
                            <li>• KPIs personalizables en tiempo real</li>
                            <li>• Predicciones basadas en ML</li>
                            <li>• Alertas y notificaciones automáticas</li>
                        </ul>
                        <button className="w-full bg-[var(--color-azul)] text-white py-2 px-4 rounded hover:opacity-90 transition-opacity">
                            Ver Demo
                        </button>
                    </div>
                </div>

                <div className="border rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                    <div className="p-6 bg-gray-50">
                        <BrainCircuit className="w-12 h-12 text-[var(--color-azul)] mb-4" />
                        <h2 className="text-2xl font-bold mb-3">Automatización de Procesos</h2>
                        <p className="text-gray-600 mb-4">
                            Sistema de automatización que optimiza flujos de trabajo y reduce tareas manuales.
                        </p>
                        <ul className="text-sm text-gray-600 mb-6">
                            <li>• Workflows personalizables</li>
                            <li>• Integración con sistemas existentes</li>
                            <li>• Reportes de eficiencia</li>
                        </ul>
                        <button className="w-full bg-[var(--color-azul)] text-white py-2 px-4 rounded hover:opacity-90 transition-opacity">
                            Explorar Demo
                        </button>
                    </div>
                </div>

                <div className="border rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                    <div className="p-6 bg-gray-50">
                        <ShieldCheck className="w-12 h-12 text-[var(--color-azul)] mb-4" />
                        <h2 className="text-2xl font-bold mb-3">Seguridad Inteligente</h2>
                        <p className="text-gray-600 mb-4">
                            Sistema de monitoreo y protección con IA para detectar y prevenir amenazas.
                        </p>
                        <ul className="text-sm text-gray-600 mb-6">
                            <li>• Detección proactiva de amenazas</li>
                            <li>• Análisis de comportamiento</li>
                            <li>• Reportes de seguridad</li>
                        </ul>
                        <button className="w-full bg-[var(--color-azul)] text-white py-2 px-4 rounded hover:opacity-90 transition-opacity">
                            Ver Demo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DemosPage;