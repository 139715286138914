// src/components/ui/Section.jsx
import React from 'react';
import Container from './Container';

/**
 * Componente de sección responsiva para estructura consistente de páginas
 * @param {ReactNode} children - Contenido de la sección
 * @param {string} bgColor - Color de fondo (puede ser un color CSS válido o clase bg- de Tailwind)
 * @param {string} spacing - Espaciado vertical de la sección (tight, normal, wide)
 * @param {string} className - Clases adicionales para personalización
 * @param {boolean} fluid - Si el contenedor interno debe ser fluido
 */
const Section = ({
                     children,
                     bgColor = "white",
                     spacing = "normal",
                     className = '',
                     fluid = false
                 }) => {
    const spacingClasses = {
        tight: "py-6 md:py-10",
        normal: "py-10 md:py-16",
        wide: "py-16 md:py-24",
    };

    // Determinar si bgColor es una clase de Tailwind o un color CSS
    const bgColorClass = bgColor.startsWith('bg-') ? bgColor : `bg-${bgColor}`;

    return (
        <section className={`w-full ${bgColorClass} ${spacingClasses[spacing]} ${className}`}>
            <Container fluid={fluid}>
                {children}
            </Container>
        </section>
    );
};

export default Section;