// components/ChatWidget/ChatContext.jsx
import React, { createContext, useContext, useState, useCallback } from 'react';

const ChatContext = createContext();

export function ChatProvider({ children, config = {} }) {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);

    const sendMessage = useCallback(async (message) => {
        // Add user message
        const userMessage = {
            id: Date.now(),
            text: message,
            sender: 'user'
        };

        setMessages(prev => [...prev, userMessage]);

        // Here you would integrate with your chat backend
        // For example:
        // try {
        //   const response = await fetch('/api/chat', {
        //     method: 'POST',
        //     body: JSON.stringify({ message }),
        //   });
        //   const data = await response.json();
        //   setMessages(prev => [...prev, {
        //     id: Date.now(),
        //     text: data.response,
        //     sender: 'bot'
        //   }]);
        // } catch (error) {
        //   console.error('Chat error:', error);
        // }
    }, []);

    const clearMessages = useCallback(() => {
        setMessages([]);
    }, []);

    const value = {
        isOpen,
        setIsOpen,
        messages,
        sendMessage,
        clearMessages,
        config
    };

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
}

export function useChatWidget() {
    const context = useContext(ChatContext);
    if (!context) {
        throw new Error('useChatWidget must be used within a ChatProvider');
    }
    return context;
}

// Usage in _app.jsx or layout component:
/*
import { ChatProvider } from '@/components/ChatWidget/ChatContext';
import ChatWidget from '@/components/ChatWidget';

function MyApp({ Component, pageProps }) {
  const chatConfig = {
    position: 'bottom-right',
    primaryColor: 'var(--color-azul)',
    title: 'Asistente Digital',
    subtitle: '¡Hola! ¿En qué puedo ayudarte?'
  };

  return (
    <ChatProvider config={chatConfig}>
      <Component {...pageProps} />
      <ChatWidget />
    </ChatProvider>
  );
}
*/