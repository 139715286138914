// src/pages/HomePage.jsx
import React from 'react';
import {
    Search,
    Lightbulb,
    Cog,
    ArrowUpRight,
    Target,
    Rocket,
    CheckCircle,
    TrendingUp
} from 'lucide-react';
import Card from '../components/ui/Card';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function HomePage() {
    return (
        <div className="w-full min-h-screen">
            {/* Hero Section with Pulsing Dots */}
            <section className="relative w-full bg-[#2b3990] overflow-hidden py-24">
                <div className="absolute inset-0">
                    {/* Pulsing dots background */}
                    <div className="absolute inset-0 pulsing-dots" style={{
                        backgroundImage: 'radial-gradient(circle at 1px 1px, white 1px, transparent 0)',
                        backgroundSize: '40px 40px'
                    }}></div>
                </div>

                <div className="relative w-full max-w-[1400px] mx-auto px-6">
                    <div className="flex flex-col items-center max-w-3xl mx-auto text-center">
                        <h1 className="text-4xl md:text-5xl font-extrabold tracking-tight text-white uppercase text-center leading-tight">
                            TRANSFORMACIÓN DIGITAL QUE GENERA RESULTADOS
                        </h1>
                        <p className="text-xl text-white/90 mb-12">
                Convertimos desafíos empresariales en oportunidades de crecimiento
                mediante soluciones digitales prácticas, medibles y sostenibles
            </p>
            <div className="flex gap-6">
                <Link
                    to="/how-we-do-it"
                    className="bg-white text-[#2b3990] px-8 py-4 rounded-lg font-semibold hover:bg-white/90 transition-all flex items-center gap-3 group"
                >
                    EXPLORAR SOLUCIONES
                    <ArrowUpRight
                        className="group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform"
                    />
                </Link>
            </div>
                    </div>
                </div>

                {/* CSS for pulsing animation */}
                <style jsx>{`
                    @keyframes pulse {
                        0% {
                            opacity: 0.15;
                        }
                        50% {
                            opacity: 0.25;
                        }
                        100% {
                            opacity: 0.15;
                        }
                    }
                    
                    .pulsing-dots {
                        animation: pulse 4s ease-in-out infinite;
                    }
                `}</style>
            </section>

            {/* Core Values Section */}
            <section className="w-full py-16 bg-gray-50">
                <div className="max-w-[1400px] mx-auto px-6">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="flex flex-col items-center text-center p-6">
                            <Target className="w-12 h-12 text-[#2b3990] mb-4" />
                            <h3 className="text-xl font-semibold mb-3">Estrategia que Vende</h3>
                            <p className="text-gray-600">
                                Transformación comercial alineada con objetivos de negocio medibles
                            </p>
                        </div>
                        <div className="flex flex-col items-center text-center p-6">
                            <Rocket className="w-12 h-12 text-[#2b3990] mb-4" />
                            <h3 className="text-xl font-semibold mb-3">Tecnología que Escala</h3>
                            <p className="text-gray-600">
                                Soluciones tecnológicas adaptables que crecen con tu negocio
                            </p>
                        </div>
                        <div className="flex flex-col items-center text-center p-6">
                            <TrendingUp className="w-12 h-12 text-[#2b3990] mb-4" />
                            <h3 className="text-xl font-semibold mb-3">Resultados que Transforman</h3>
                            <p className="text-gray-600">
                                Impacto medible y sostenible desde el primer mes
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Solutions Section */}
            <section className="w-full py-24 bg-white">
                <div className="max-w-[1400px] mx-auto px-6">
                    <div className="text-center max-w-3xl mx-auto mb-16">
                        <h2 className="text-4xl font-bold mb-6 uppercase tracking-wide text-[#2b3990]">
                            NUESTRAS SOLUCIONES
                        </h2>
                        <p className="text-gray-600">
                            Un enfoque integral que combina diagnóstico profundo,
                            implementación|Ejecución y acompañamiento continuo
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Primero, vamos a usar un div normal para comprobar que funciona */}
                        <div className="bg-white rounded-xl shadow-lg p-6">
                            <div className="w-16 h-16 rounded-full bg-[#2b3990]/10
                            flex items-center justify-center mb-6">
                                <Search className="w-8 h-8 text-[#2b3990]" />
                            </div>
                            <h3 className="text-2xl font-semibold mb-4 uppercase">
                                DIAGNÓSTICO PROFUNDO
                            </h3>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Análisis exhaustivo de procesos y oportunidades</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Mapeo del journey del cliente y puntos de dolor</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Identificación de oportunidades de automatización</span>
                                </li>
                            </ul>
                        </div>

                        {/* Solo por prueba, usemos el componente Card simple */}
                        <Card className="p-6">
                            <div className="w-16 h-16 rounded-full bg-[#2b3990]/10
                            flex items-center justify-center mb-6">
                                <Lightbulb className="w-8 h-8 text-[#2b3990]" />
                            </div>
                            <h3 className="text-2xl font-semibold mb-4 uppercase">
                                IMPLEMENTACIÓN y EJECUCIÓN
                            </h3>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Soluciones de IA y automatización integradas</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Sistemas centralizados y flujos optimizados</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Transformación gradual y sostenible</span>
                                </li>
                            </ul>
                        </Card>

                        <div className="bg-white rounded-xl shadow-lg p-6">
                            <div className="w-16 h-16 rounded-full bg-[#2b3990]/10
                            flex items-center justify-center mb-6">
                                <Cog className="w-8 h-8 text-[#2b3990]" />
                            </div>
                            <h3 className="text-2xl font-semibold mb-4 uppercase">
                                ACOMPAÑAMIENTO CONTINUO
                            </h3>
                            <ul className="space-y-3 text-gray-600">
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Optimización continua de procesos</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Métricas y KPIs en tiempo real</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <CheckCircle className="w-5 h-5 text-[#2b3990] shrink-0 mt-1" />
                                    <span>Asesoría estratégica permanente</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="w-full py-16 bg-gray-50">
                <div className="max-w-3xl mx-auto px-6 text-center">
                    <h2 className="text-3xl font-bold mb-6 text-[#2b3990]">
                        COMIENZA TU TRANSFORMACIÓN DIGITAL
                    </h2>
                    <p className="text-lg text-gray-600 mb-8">
                        Agenda una consultoría inicial sin compromiso y descubre cómo podemos
                        ayudarte a potenciar tu negocio con soluciones digitales a medida.
                    </p>
                    <a
                        href="mailto:rodrigo@peregrino.co"
                        className="inline-flex items-center justify-center px-8 py-3
                        border border-transparent rounded-md shadow-sm text-base
                        font-medium text-white bg-[#2b3990] hover:bg-[#232e73] transition-colors"
                    >
                        Contáctanos Hoy
                    </a>
                </div>
            </section>
        </div>
    );
}

export default HomePage;