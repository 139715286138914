import React from 'react';

function NotFoundPage() {
    return (
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
            <h1>Página no encontrada</h1>
            <p>Lo sentimos, la ruta que buscas no existe.</p>
        </div>
    );
}

export default NotFoundPage;
