// src/pages/ProposalGenerator.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check, Download, ArrowRight, ChevronDown, ChevronUp, Copy, Upload, Save, FileText, Printer } from 'lucide-react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import html2pdf from 'html2pdf.js';  // Import the html2pdf library
import Section from '../components/ui/Section';
import Container from '../components/ui/Container';
import { Heading, Paragraph } from '../components/ui/Typography';
import Button from '../components/ui/Button';

const ProposalGenerator = () => {
    const [markdownInput, setMarkdownInput] = useState('');
    const [proposalTitle, setProposalTitle] = useState('');
    const [proposalSubtitle, setProposalSubtitle] = useState('');
    const [activePreviewSection, setActivePreviewSection] = useState('all');
    const [parsedSections, setParsedSections] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const previewRef = useRef(null);
    const proposalContentRef = useRef(null);

    // Process markdown into sections
    useEffect(() => {
        if (!markdownInput) return;

        // Detect title and subtitle from first lines
        const lines = markdownInput.split('\n');
        let titleDetected = false;
        let subtitleDetected = false;

        for (let i = 0; i < Math.min(10, lines.length); i++) {
            const line = lines[i].trim();
            if (line.startsWith('# ') && !titleDetected) {
                setProposalTitle(line.replace('# ', ''));
                titleDetected = true;
                continue;
            }

            if ((line.startsWith('## ') || line.startsWith('# ')) && titleDetected && !subtitleDetected) {
                setProposalSubtitle(line.replace(/^[#]+ /, ''));
                subtitleDetected = true;
                break;
            }
        }

        // Split markdown by h2 headings to create sections
        const sectionPattern = /(?=^## .*$)/gm;
        const splitContent = markdownInput.split(sectionPattern);

        // Process each section
        const sections = splitContent.map(section => {
            const lines = section.trim().split('\n');
            let title = '';
            let content = section;

            // Extract section title (h2)
            if (lines[0] && lines[0].startsWith('## ')) {
                title = lines[0].replace('## ', '');
                content = lines.slice(1).join('\n');
            }

            // Generate ID from title
            const id = title.toLowerCase()
                .replace(/[^\w\s]/g, '')
                .replace(/\s+/g, '-');

            return {
                id: id || 'intro',
                title: title || 'Introducción',
                content,
                html: DOMPurify.sanitize(marked.parse(content))
            };
        });

        // Ensure we have at least an intro section if none was detected
        if (sections.length === 0 || (sections.length === 1 && !sections[0].title)) {
            const introSection = {
                id: 'intro',
                title: 'Introducción',
                content: markdownInput,
                html: DOMPurify.sanitize(marked.parse(markdownInput))
            };
            setParsedSections([introSection]);
        } else {
            setParsedSections(sections);
        }
    }, [markdownInput]);

    // Handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            setMarkdownInput(e.target.result);
        };
        reader.readAsText(file);
    };

    // Generate proposal preview
    const generatePreview = () => {
        setIsGenerating(true);

        // Simulating processing time
        setTimeout(() => {
            setIsPreviewMode(true);
            setIsGenerating(false);

            // Scroll to preview
            setTimeout(() => {
                if (previewRef.current) {
                    previewRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }, 1000);
    };

    // Export to PDF function
    const generatePDF = () => {
        if (!proposalContentRef.current) return;

        // Define PDF options
        const options = {
            margin: [10, 10, 10, 10],
            filename: `${proposalTitle.toLowerCase().replace(/\s+/g, '-')}-propuesta.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, useCORS: true, logging: false },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };

        // Create a clone of the content for PDF generation
        // This allows us to add PDF-specific styling without affecting the UI
        const content = proposalContentRef.current.cloneNode(true);

        // Add PDF-specific styles
        const style = document.createElement('style');
        style.innerHTML = `
      @page {
        margin: 10mm;
      }
      body {
        font-family: 'Poppins', Arial, sans-serif;
      }
      .section-break {
        page-break-before: always;
      }
      .avoid-break {
        page-break-inside: avoid;
      }
      .hero-section {
        background-color: #2b3990 !important;
        color: white !important;
        padding: 30mm 10mm !important;
        text-align: center;
      }
      h1, h2, h3, h4 {
        color: #2b3990;
      }
      .footer {
        text-align: center;
        color: #2b3990;
        margin-top: 10mm;
        border-top: 1px solid #2b3990;
        padding-top: 5mm;
      }
    `;
        content.appendChild(style);

        // Add page breaks between sections
        const sections = content.querySelectorAll('.proposal-section');
        sections.forEach((section, index) => {
            if (index > 0) {
                section.classList.add('section-break');
            }
        });

        // Add avoid-break to important elements
        const tables = content.querySelectorAll('table');
        tables.forEach(table => {
            table.classList.add('avoid-break');
        });

        // Fix images for PDF (if any)
        const images = content.querySelectorAll('img');
        images.forEach(img => {
            if (img.src.startsWith('/')) {
                // Convert relative URLs to absolute
                img.src = window.location.origin + img.src;
            }
        });

        // Hide navigation elements from PDF
        const navElements = content.querySelectorAll('.no-print');
        navElements.forEach(el => {
            el.style.display = 'none';
        });

        // Generate PDF
        html2pdf().from(content).set(options).save();
    };

    // Print proposal
    const printProposal = () => {
        window.print();
    };

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Hero Banner */}
            <section className="w-full bg-[#2b3990] py-16">
                <Container>
                    <div className="max-w-3xl">
                        <Heading variant="h1" color="white" className="mb-4">
                            GENERADOR DE PROPUESTAS
                        </Heading>
                        <Paragraph size="lg" color="white/90">
                            Convierte tu documento Markdown en una propuesta profesional con el estilo de Peregrino
                        </Paragraph>
                    </div>
                </Container>
            </section>

            {!isPreviewMode ? (
                <Section spacing="normal">
                    <Container>
                        <div className="bg-white rounded-xl shadow-md p-8 mb-8">
                            <div className="mb-8">
                                <Heading variant="h2" className="text-[#2b3990] mb-4">
                                    Subir Documento
                                </Heading>
                                <Paragraph>
                                    Sube un archivo Markdown (.md) o pega tu contenido directamente en el editor.
                                </Paragraph>
                                <div className="flex flex-wrap gap-4 mt-6">
                                    <div className="relative">
                                        <input
                                            type="file"
                                            accept=".md"
                                            onChange={handleFileUpload}
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                        />
                                        <Button variant="primary" className="flex items-center gap-2">
                                            <Upload className="w-4 h-4" />
                                            Subir archivo MD
                                        </Button>
                                    </div>
                                    <Button
                                        variant="outline"
                                        className="flex items-center gap-2"
                                        onClick={() => {
                                            // Example markdown for testing
                                            setMarkdownInput(`# Propuesta de Transformación Digital
# JAC Automóviles: Sistema Inteligente de Gestión de Leads

**Presentado por: Peregrino**

---

## Resumen Ejecutivo

Presentamos una solución integral para transformar la gestión de los 150,000 leads anuales de JAC Automóviles mediante un **Sistema Inteligente de Gestión de Leads** con implementación acelerada en solo 3 semanas.

**Puntos clave:**
- Asistente virtual automotriz especializado disponible 24/7
- Respuesta instantánea en todos los canales (web, WhatsApp, redes sociales)
- Calificación inteligente y asignación geolocalizada a distribuidores
- Procesamiento multimodal (texto, voz, imágenes, documentos)
- Integración completa con CRM y sistemas existentes

**Beneficios principales:**
- Reducción del 80% en tiempo de respuesta
- Incremento del 35-40% en tasa de conversión
- Mejora del 60% en eficiencia de asignación
- Hasta 1,800 ventas adicionales anuales
- ROI completo en 6-8 meses

## Diagnóstico Inicial

### Las Dos Dimensiones del Negocio Automotriz

El éxito en el sector automotriz depende de la gestión efectiva de dos dimensiones paralelas que deben estar perfectamente sincronizadas.

## Solución Propuesta

Nuestra solución se basa en un **Sistema Inteligente para la Gestión de Leads**, diseñado específicamente para el sector automotriz y personalizado para JAC.

## Plan de Implementación

Hemos diseñado un plan de implementación rápido de solo 3 semanas.

## ROI Proyectado

El sistema generará un retorno de inversión significativo a través de múltiples canales de valor.

## Propuesta Económica

**Setup: USD 5,000**
- Implementación completa del sistema en 3 semanas
- Integración con canales y CRM existentes
- Configuración y entrenamiento del Asistente IA`);
                                        }}
                                    >
                                        <Copy className="w-4 h-4" />
                                        Cargar ejemplo
                                    </Button>
                                </div>
                            </div>

                            <div className="mb-8">
                                <Heading variant="h3" className="mb-4">
                                    Editor de Markdown
                                </Heading>
                                <textarea
                                    value={markdownInput}
                                    onChange={(e) => setMarkdownInput(e.target.value)}
                                    className="w-full p-4 border border-gray-300 rounded-md font-mono h-[600px]"
                                    placeholder="# Título de la propuesta&#10;## Subtítulo o cliente&#10;&#10;## Resumen Ejecutivo&#10;Texto del resumen...&#10;&#10;## Solución Propuesta&#10;Detalles de la solución..."
                                />
                            </div>

                            <div className="flex justify-end">
                                <Button
                                    variant="primary"
                                    disabled={!markdownInput.trim() || isGenerating}
                                    onClick={generatePreview}
                                    className="flex items-center gap-2"
                                >
                                    {isGenerating ? (
                                        <>
                                            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                                            Generando...
                                        </>
                                    ) : (
                                        <>
                                            Generar Propuesta <ArrowRight className="w-4 h-4" />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Container>
                </Section>
            ) : (
                <div ref={previewRef}>
                    {/* Preview Mode */}
                    <div className="sticky top-0 bg-white border-b border-gray-200 z-40 shadow-sm no-print">
                        <Container>
                            <div className="py-4 flex justify-between items-center">
                                <div className="flex items-center gap-4">
                                    <Button
                                        variant="outline"
                                        onClick={() => setIsPreviewMode(false)}
                                        className="flex items-center gap-2"
                                    >
                                        <ArrowRight className="w-4 h-4 rotate-180" />
                                        Volver al Editor
                                    </Button>
                                    <div className="text-sm text-gray-500">
                                        Vista previa de la propuesta
                                    </div>
                                </div>
                                <div className="flex gap-2">
                                    <Button
                                        variant="outline"
                                        onClick={printProposal}
                                        className="flex items-center gap-2"
                                    >
                                        <Printer className="w-4 h-4" />
                                        Imprimir
                                    </Button>
                                    <Button
                                        variant="outline"
                                        onClick={generatePDF}
                                        className="flex items-center gap-2"
                                    >
                                        <FileText className="w-4 h-4" />
                                        Exportar PDF
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className="flex items-center gap-2"
                                    >
                                        <Save className="w-4 h-4" />
                                        Guardar Propuesta
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>

                    {/* Section Navigation */}
                    <div className="sticky top-16 bg-white border-b border-gray-200 z-30 no-print">
                        <Container>
                            <div className="flex flex-wrap items-center py-4 gap-4">
                                <button
                                    onClick={() => setActivePreviewSection('all')}
                                    className={`whitespace-nowrap px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                                        activePreviewSection === 'all'
                                            ? 'bg-[#2b3990] text-white'
                                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                    }`}
                                >
                                    Vista Completa
                                </button>
                                <div className="flex-1 overflow-x-auto hide-scrollbar">
                                    <div className="flex flex-wrap gap-2 md:gap-4">
                                        {parsedSections.map((section) => (
                                            <button
                                                key={section.id}
                                                onClick={() => setActivePreviewSection(section.id)}
                                                className={`whitespace-nowrap px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                                                    activePreviewSection === section.id
                                                        ? 'bg-[#2b3990] text-white'
                                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                }`}
                                            >
                                                {section.title}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>

                    {/* Proposal Content for PDF generation */}
                    <div ref={proposalContentRef}>
                        {/* Hero Section */}
                        <section className="w-full bg-[#2b3990] py-16 hero-section">
                            <Container>
                                <div className="max-w-3xl mx-auto">
                                    <h1 className="text-4xl md:text-5xl font-bold text-white uppercase mb-4">
                                        {proposalTitle}
                                    </h1>
                                    <p className="text-2xl text-white/90 mb-4">
                                        {proposalSubtitle}
                                    </p>
                                    <p className="text-xl text-white/80">
                                        Presentado por: Peregrino
                                    </p>
                                </div>
                            </Container>
                        </section>

                        {/* Proposal Content */}
                        <div className="py-12">
                            <Container>
                                {activePreviewSection === 'all' ? (
                                    // Render all sections
                                    parsedSections.map((section) => (
                                        <div
                                            key={section.id}
                                            id={section.id}
                                            className="mb-12 proposal-section"
                                        >
                                            {section.title !== 'Introducción' && (
                                                <h2 className="text-3xl font-bold text-[#2b3990] mb-6">{section.title}</h2>
                                            )}
                                            <div className="bg-white rounded-xl shadow-md p-8 mb-8">
                                                <div
                                                    className="prose prose-lg max-w-none"
                                                    dangerouslySetInnerHTML={{ __html: section.html }}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    // Render only the active section
                                    parsedSections
                                        .filter(section => section.id === activePreviewSection)
                                        .map((section) => (
                                            <div
                                                key={section.id}
                                                id={section.id}
                                                className="mb-12 proposal-section"
                                            >
                                                {section.title !== 'Introducción' && (
                                                    <h2 className="text-3xl font-bold text-[#2b3990] mb-6">{section.title}</h2>
                                                )}
                                                <div className="bg-white rounded-xl shadow-md p-8 mb-8">
                                                    <div
                                                        className="prose prose-lg max-w-none"
                                                        dangerouslySetInnerHTML={{ __html: section.html }}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                )}
                            </Container>
                        </div>

                        {/* Footer CTA */}
                        <div className="bg-[#2b3990] py-12">
                            <Container>
                                <div className="text-center text-white">
                                    <p className="text-lg italic mb-8">
                                        "En Peregrino transformamos cada contacto en una oportunidad real. Nuestra tecnología no solo responde - califica, asigna y convierte."
                                    </p>
                                    <div className="text-2xl font-bold mb-6">
                                        ¿Están listos para revolucionar su negocio digital?
                                    </div>
                                    <div className="no-print">
                                        <Button
                                            variant="outline"
                                            className="bg-white text-[#2b3990] border-white hover:bg-white/90"
                                        >
                      <span className="flex items-center gap-2">
                        Agendar una reunión <ArrowRight className="w-5 h-5" />
                      </span>
                                        </Button>
                                    </div>
                                    <div className="hidden print:block footer">
                                        <p>© {new Date().getFullYear()} Peregrino Colombia SAS. Todos los derechos reservados.</p>
                                        <p>contacto@peregrino.co | +57 316 289 2694</p>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                </div>
            )}

            {/* Print Styles */}
            <style jsx global>{`
        @media print {
          body {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
          }
          .no-print {
            display: none !important;
          }
          .proposal-section {
            page-break-before: always;
          }
          .proposal-section:first-child {
            page-break-before: avoid;
          }
        }
      `}</style>

            <style jsx>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .prose h1, .prose h2, .prose h3, .prose h4 {
          color: #2b3990;
        }
        .prose a {
          color: #2b3990;
        }
        .prose strong {
          color: #000;
        }
      `}</style>
        </div>
    );
};

export default ProposalGenerator;