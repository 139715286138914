// src/components/ui/Card.jsx
// Versión simplificada del Card
import React from 'react';

const Card = ({ children, className = '', ...props }) => {
    return (
        <div className={`bg-white rounded-lg shadow-sm ${className}`} {...props}>
            {children}
        </div>
    );
};

// Define subcomponentes simples
const CardHeader = ({ children, className = '' }) => (
    <div className={`p-6 ${className}`}>{children}</div>
);

const CardTitle = ({ children, className = '' }) => (
    <h3 className={`text-xl font-semibold mb-2 ${className}`}>{children}</h3>
);

const CardContent = ({ children, className = '' }) => (
    <div className={`p-6 pt-0 ${className}`}>{children}</div>
);

// Asignarlos al componente principal
Card.Header = CardHeader;
Card.Title = CardTitle;
Card.Content = CardContent;

// Exportar todo
export { Card, CardHeader, CardTitle, CardContent };
export default Card;