// src/pages/BlogPostDetail.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Calendar, User, Tag, Clock, ChevronLeft, ChevronRight, Share2 } from 'lucide-react';
import Section from '../components/ui/Section';
import Container from '../components/ui/Container';
import { Heading, Paragraph } from '../components/ui/Typography';
import { Grid } from '../components/ui/Grid';
import Button from '../components/ui/Button';
import { sampleBlogPosts, getCategoryName } from '../data/blogData';

const BlogPostDetail = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [relatedPosts, setRelatedPosts] = useState([]);

    useEffect(() => {
        // Simulación de carga del post específico
        setLoading(true);
        const selectedPost = sampleBlogPosts.find(p => p.slug === slug);

        if (selectedPost) {
            setPost(selectedPost);
            // Obtener posts relacionados de la misma categoría
            const related = sampleBlogPosts
                .filter(p => p.category === selectedPost.category && p.id !== selectedPost.id)
                .slice(0, 3);
            setRelatedPosts(related);
        }

        setLoading(false);
        // Scroll al inicio cuando cambia el post
        window.scrollTo(0, 0);
    }, [slug]);

    if (loading) {
        return (
            <Section spacing="normal">
                <Container>
                    <div className="text-center py-20">
                        <p className="text-lg text-gray-600">Cargando artículo...</p>
                    </div>
                </Container>
            </Section>
        );
    }

    if (!post) {
        return (
            <Section spacing="normal">
                <Container>
                    <div className="text-center py-20">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">Artículo no encontrado</h2>
                        <p className="text-lg text-gray-600 mb-8">No pudimos encontrar el artículo que estás buscando.</p>
                        <Button variant="primary" to="/blog">Volver al blog</Button>
                    </div>
                </Container>
            </Section>
        );
    }

    // Estructura del artículo de blog detallado
    return (
        <div className="min-h-screen bg-white">
            {/* Hero del artículo */}
            <section className="w-full bg-peregrino-blue py-16">
                <Container>
                    <div className="max-w-3xl mx-auto text-center">
                        <div className="flex justify-center gap-3 text-white/80 mb-4">
                            <span className="inline-flex items-center">
                                <Calendar className="w-4 h-4 mr-1" />
                                {post.date}
                            </span>
                            <span className="inline-flex items-center">
                                <User className="w-4 h-4 mr-1" />
                                {post.author}
                            </span>
                            <span className="inline-flex items-center">
                                <Tag className="w-4 h-4 mr-1" />
                                {getCategoryName(post.category)}
                            </span>
                        </div>
                        <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">
                            {post.title}
                        </h1>
                        <p className="text-lg text-white/90">
                            {post.excerpt}
                        </p>
                    </div>
                </Container>
            </section>

            {/* Contenido del artículo */}
            <Section spacing="normal">
                <Container>
                    <div className="max-w-3xl mx-auto">
                        {/* Imagen de portada */}
                        <div className="mb-8 rounded-lg overflow-hidden shadow-lg">
                            <img
                                src={post.coverImage}
                                alt={post.title}
                                className="w-full h-auto"
                            />
                        </div>

                        {/* Contenido del artículo */}
                        <div className="prose prose-lg max-w-none mb-10">
                            {/* Aquí iría el contenido HTML del artículo */}
                            <div dangerouslySetInnerHTML={{ __html: post.content || `
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in dui mauris. Vivamus hendrerit arcu sed erat molestie vehicula. Sed auctor neque eu tellus rhoncus ut eleifend nibh porttitor. Ut in nulla enim. Phasellus molestie magna non est bibendum non venenatis nisl tempor.
                                </p>
                                <h2>La transformación digital como ventaja competitiva</h2>
                                <p>
                                    Suspendisse lectus leo, consectetur in tempor sit amet, placerat quis neque. Etiam luctus porttitor lorem, sed suscipit est rutrum non. Curabitur lobortis nisl a enim congue semper. Aenean commodo ultrices imperdiet. Vestibulum ut justo vel sapien venenatis tincidunt.
                                </p>
                                <p>
                                    Phasellus eget dolor sit amet ipsum dapibus condimentum vitae quis lectus. Aliquam ut massa in turpis dapibus convallis. Praesent elit lacus, vestibulum at malesuada et, ornare et est. Ut augue nunc, sodales ut euismod non, adipiscing vitae orci. Mauris ut placerat justo.
                                </p>
                                <blockquote>
                                    La transformación digital no se trata solo de tecnología, sino de cómo utilizamos esa tecnología para transformar la manera en que operamos y entregamos valor a nuestros clientes.
                                </blockquote>
                                <h3>Implementación práctica para PYMEs</h3>
                                <p>
                                    Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo.
                                </p>
                                <ul>
                                    <li>Análisis de necesidades específicas</li>
                                    <li>Priorización de iniciativas de alto impacto</li>
                                    <li>Implementación escalonada y medición de resultados</li>
                                    <li>Optimización continua basada en datos</li>
                                </ul>
                                <p>
                                    Etiam porta sem malesuada magna mollis euismod. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec ullamcorper nulla non metus auctor fringilla.
                                </p>
                            `}} />
                        </div>

                        {/* Etiquetas y compartir */}
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 py-4 border-t border-b mb-10">
                            <div className="flex flex-wrap gap-2">
                                <span className="text-gray-700 font-medium">Tags:</span>
                                {post.tags && post.tags.map(tag => (
                                    <Link
                                        key={tag}
                                        to={`/blog/tag/${tag.toLowerCase().replace(' ', '-')}`}
                                        className="bg-gray-100 hover:bg-peregrino-blue hover:text-white px-3 py-1 rounded-full text-sm transition-colors"
                                    >
                                        {tag}
                                    </Link>
                                ))}
                                {!post.tags && ['Transformación Digital', 'PYMEs', 'Colombia'].map(tag => (
                                    <Link
                                        key={tag}
                                        to={`/blog/tag/${tag.toLowerCase().replace(' ', '-')}`}
                                        className="bg-gray-100 hover:bg-peregrino-blue hover:text-white px-3 py-1 rounded-full text-sm transition-colors"
                                    >
                                        {tag}
                                    </Link>
                                ))}
                            </div>

                            <div className="flex items-center gap-3">
                                <span className="text-gray-700">Compartir:</span>
                                <button
                                    className="w-8 h-8 flex items-center justify-center bg-[#3b5998] text-white rounded-full"
                                    onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')}
                                    aria-label="Compartir en Facebook"
                                >
                                    <i className="fab fa-facebook-f"></i>
                                </button>
                                <button
                                    className="w-8 h-8 flex items-center justify-center bg-[#1da1f2] text-white rounded-full"
                                    onClick={() => window.open(`https://twitter.com/intent/tweet?url=${window.location.href}&text=${post.title}`, '_blank')}
                                    aria-label="Compartir en Twitter"
                                >
                                    <i className="fab fa-twitter"></i>
                                </button>
                                <button
                                    className="w-8 h-8 flex items-center justify-center bg-[#0a66c2] text-white rounded-full"
                                    onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`, '_blank')}
                                    aria-label="Compartir en LinkedIn"
                                >
                                    <i className="fab fa-linkedin-in"></i>
                                </button>
                                <button
                                    className="w-8 h-8 flex items-center justify-center bg-gray-200 text-gray-700 rounded-full"
                                    onClick={() => {
                                        navigator.clipboard.writeText(window.location.href);
                                        alert('Enlace copiado al portapapeles');
                                    }}
                                    aria-label="Copiar enlace"
                                >
                                    <Share2 className="w-4 h-4" />
                                </button>
                            </div>
                        </div>

                        {/* Autor */}
                        <div className="bg-gray-50 rounded-lg p-6 mb-10">
                            <div className="flex flex-col sm:flex-row items-center sm:items-start gap-5">
                                <div className="w-20 h-20 rounded-full overflow-hidden bg-gray-200 flex-shrink-0">
                                    <img
                                        src={post.authorImage || "https://via.placeholder.com/150"}
                                        alt={post.author}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                                <div>
                                    <h3 className="text-xl font-bold mb-2">{post.author}</h3>
                                    <p className="text-gray-600 mb-3">
                                        {post.authorBio || "Especialista en transformación digital con más de 10 años de experiencia trabajando con empresas colombianas. Enfocado en crear soluciones prácticas y orientadas a resultados."}
                                    </p>
                                    <div className="flex gap-2">
                                        <Link to={`/blog/author/${post.author.toLowerCase().replace(' ', '-')}`} className="text-peregrino-blue hover:underline text-sm">
                                            Ver más artículos
                                        </Link>
                                        {post.authorLinkedIn && (
                                            <>
                                                <span className="text-gray-300">|</span>
                                                <a href={post.authorLinkedIn} target="_blank" rel="noopener noreferrer" className="text-peregrino-blue hover:underline text-sm">
                                                    LinkedIn
                                                </a>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Navegación entre posts */}
                        <div className="flex flex-col sm:flex-row justify-between gap-4 mb-10">
                            <Button
                                variant="outline"
                                className="flex items-center justify-center"
                                onClick={() => navigate(-1)}
                            >
                                <ChevronLeft className="w-5 h-5 mr-2" />
                                Artículo anterior
                            </Button>
                            <Button
                                variant="outline"
                                className="flex items-center justify-center"
                                to="/blog"
                            >
                                Próximo artículo
                                <ChevronRight className="w-5 h-5 ml-2" />
                            </Button>
                        </div>

                        {/* Formulario de comentarios */}
                        <div className="mb-16">
                            <h3 className="text-2xl font-bold mb-6">Deja un comentario</h3>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                    <div>
                                        <label htmlFor="name" className="block text-gray-700 mb-2">Nombre</label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-peregrino-blue focus:border-transparent"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-peregrino-blue focus:border-transparent"
                                        />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="comment" className="block text-gray-700 mb-2">Comentario</label>
                                    <textarea
                                        id="comment"
                                        rows="5"
                                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-peregrino-blue focus:border-transparent"
                                    ></textarea>
                                </div>
                                <div className="flex items-center mb-4">
                                    <input type="checkbox" id="save-info" className="mr-2" />
                                    <label htmlFor="save-info" className="text-gray-700">
                                        Guardar mi nombre y email para la próxima vez que comente
                                    </label>
                                </div>
                                <Button variant="primary" type="submit">
                                    Publicar comentario
                                </Button>
                            </form>
                        </div>
                    </div>
                </Container>
            </Section>

            {/* Posts relacionados */}
            {relatedPosts.length > 0 && (
                <Section bgColor="gray-50" spacing="normal">
                    <Container>
                        <div className="text-center mb-10">
                            <h2 className="text-3xl font-bold">Artículos relacionados</h2>
                            <div className="w-20 h-1 bg-peregrino-blue mx-auto mt-4"></div>
                        </div>

                        <Grid columns="1 md:2 lg:3" spacing="lg">
                            {relatedPosts.map(relatedPost => (
                                <BlogCard key={relatedPost.id} post={relatedPost} />
                            ))}
                        </Grid>

                        <div className="text-center mt-10">
                            <Button variant="outline" to="/blog">
                                Ver todos los artículos
                            </Button>
                        </div>
                    </Container>
                </Section>
            )}
        </div>
    );
};

// Componente de tarjeta para artículos relacionados
const BlogCard = ({ post }) => {
    return (
        <article className="bg-white rounded-lg shadow-sm overflow-hidden transition-shadow hover:shadow-md h-full flex flex-col">
            <Link to={`/blog/${post.slug}`} className="block">
                <div className="aspect-video bg-gray-200 overflow-hidden">
                    <img
                        src={post.coverImage}
                        alt={post.title}
                        className="w-full h-full object-cover transition-transform hover:scale-105"
                    />
                </div>
            </Link>
            <div className="p-6 flex flex-col flex-grow">
                <div className="flex items-center gap-3 text-sm text-gray-500 mb-4">
                    <span className="inline-flex items-center">
                        <Calendar className="w-4 h-4 mr-1" />
                        {post.date}
                    </span>
                </div>

                <Link to={`/blog/${post.slug}`} className="block mb-auto">
                    <h2 className="text-xl font-bold text-gray-900 mb-3 hover:text-peregrino-blue transition-colors">
                        {post.title}
                    </h2>
                </Link>

                <p className="text-gray-600 mb-4 line-clamp-3">
                    {post.excerpt}
                </p>

                <div className="mt-auto pt-4">
                    <Link
                        to={`/blog/${post.slug}`}
                        className="text-peregrino-blue font-medium hover:underline"
                    >
                        Leer artículo
                    </Link>
                </div>
            </div>
        </article>
    );
};

export default BlogPostDetail;