// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChatProvider } from './components/ui/chat-context';
import { Helmet } from 'react-helmet';

// Componentes de layout
import Layout from './components/Layout';

// Páginas
import HomePage from './pages/HomePage';
import About from './pages/About';
import HowWeDoIt from './pages/HowWeDoIt';
import ServicesPage from './pages/ServicesPage';
import DemosPage from './pages/DemosPage';
import LoginPage from './pages/LoginPage';
import PortalPage from './pages/PortalPage';
import Blog from './pages/Blog';
import BlogPostDetail from './pages/BlogPostDetail';
import ProtectedBlogAdmin from './pages/admin/ProtectedBlogAdmin';
import ProposalGenerator from './pages/ProposalGenerator';
import NotFoundPage from './pages/NotFoundPage';

// Configuración del chat widget
const chatConfig = {
    position: 'bottom-right',
    primaryColor: 'var(--peregrino-blue)',
    title: 'Asistente Digital',
    subtitle: '¡Hola! ¿En qué puedo ayudarte?'
};

function App() {
    return (
        <ChatProvider config={chatConfig}>
            <Helmet>
                <html lang="es-CO" />
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<Layout><HomePage /></Layout>} />
                    <Route path="/about" element={<Layout><About /></Layout>} />
                    <Route path="/how-we-do-it" element={<Layout><HowWeDoIt /></Layout>} />
                    <Route path="/services" element={<Layout><ServicesPage /></Layout>} />
                    <Route path="/demos" element={<Layout><DemosPage /></Layout>} />
                    <Route path="/login" element={<Layout><LoginPage /></Layout>} />
                    <Route path="/portal" element={<Layout><PortalPage /></Layout>} />
                    <Route path="/proposals" element={<Layout><ProposalGenerator /></Layout>} />

                    {/* Mantener también las rutas en español por compatibilidad */}
                    <Route path="/nosotros" element={<Layout><About /></Layout>} />
                    <Route path="/como-lo-hacemos" element={<Layout><HowWeDoIt /></Layout>} />
                    <Route path="/propuestas" element={<Layout><ProposalGenerator /></Layout>} />

                    {/* Rutas del Blog */}
                    <Route path="/blog" element={<Layout><Blog /></Layout>} />
                    <Route path="/blog/:slug" element={<Layout><BlogPostDetail /></Layout>} />
                    <Route path="/blog/categoria/:category" element={<Layout><Blog /></Layout>} />
                    <Route path="/blog/tag/:tag" element={<Layout><Blog /></Layout>} />

                    {/* Ruta de Administración del Blog (Protegida con URL no obvia) */}
                    <Route path="/gestion-contenido/7uqkzTp9" element={<ProtectedBlogAdmin />} />

                    {/* Ruta para página no encontrada */}
                    <Route path="*" element={<Layout><NotFoundPage /></Layout>} />
                </Routes>
            </Router>
        </ChatProvider>
    );
}

export default App;