// src/components/ui/Container.jsx
import React from 'react';

/**
 * Componente contenedor responsivo que mantiene consistencia en todo el sitio
 * @param {ReactNode} children - Contenido del contenedor
 * @param {string} className - Clases adicionales para personalización
 * @param {boolean} fluid - Si es true, el contenedor ocupará todo el ancho disponible
 */
const Container = ({ children, className = '', fluid = false }) => {
    return (
        <div className={`w-full px-4 sm:px-6 lg:px-8 ${fluid ? '' : 'max-w-7xl mx-auto'} ${className}`}>
            {children}
        </div>
    );
};

export default Container;