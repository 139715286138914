import React, { useState } from 'react';

function PortalPage() {
    // Para simular login rápido
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLogin = () => setIsLoggedIn(true);

    if (!isLoggedIn) {
        return (
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
                <h2>Portal de Clientes</h2>
                <p>Por favor, inicia sesión para acceder a tus demos y proyectos.</p>
                <button
                    onClick={handleLogin}
                    style={{
                        backgroundColor: 'var(--color-azul)',
                        color: '#fff',
                        border: 'none',
                        padding: '10px 20px',
                        cursor: 'pointer'
                    }}
                >
                    Iniciar Sesión
                </button>
            </div>
        );
    }

    // Si está logueado
    return (
        <div style={{ padding: '20px' }}>
            <h1 className="heading-alt" style={{ color: 'var(--color-azul)' }}>Bienvenido al Portal</h1>
            <p>Aquí puedes ver tus demos personalizados, avances de proyectos y reportes exclusivos.</p>
            {/* ... más contenido privado ... */}
        </div>
    );
}

export default PortalPage;
