import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import logoPeregrino from '../assets/images/peregrino-logo-black.png';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    // Cerrar menú móvil al cambiar de ruta
    useEffect(() => {
        setIsMenuOpen(false);
    }, [location.pathname]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="bg-white shadow-sm sticky top-0 z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-20">
                    <div className="flex">
                        <Link to="/" className="flex-shrink-0 flex items-center">
                            <img
                                className="h-12 w-auto"
                                src={logoPeregrino}
                                alt="Peregrino"
                            />
                        </Link>
                        <div className="hidden sm:ml-8 sm:flex sm:items-center sm:space-x-8">
                            <Link
                                to="/"
                                className={`px-3 py-2 text-base font-medium hover:text-[#2b3990] transition-colors ${
                                    location.pathname === '/'
                                        ? 'text-[#2b3990]'
                                        : 'text-gray-900'
                                }`}
                            >
                                Inicio
                            </Link>
                            <Link
                                to="/how-we-do-it"
                                className={`px-3 py-2 text-base font-medium hover:text-[#2b3990] transition-colors ${
                                    location.pathname === '/how-we-do-it'
                                        ? 'text-[#2b3990]'
                                        : 'text-gray-900'
                                }`}
                            >
                                Cómo lo hacemos
                            </Link>
                            <Link
                                to="/services"
                                className={`px-3 py-2 text-base font-medium hover:text-[#2b3990] transition-colors ${
                                    location.pathname === '/services'
                                        ? 'text-[#2b3990]'
                                        : 'text-gray-900'
                                }`}
                            >
                                Servicios
                            </Link>
                            <Link
                                to="/about"
                                className={`px-3 py-2 text-base font-medium hover:text-[#2b3990] transition-colors ${
                                    location.pathname === '/about'
                                        ? 'text-[#2b3990]'
                                        : 'text-gray-900'
                                }`}
                            >
                                Nosotros
                            </Link>
                            {/* Nueva opción de Blog */}
                            <Link
                                to="/blog"
                                className={`px-3 py-2 text-base font-medium hover:text-[#2b3990] transition-colors ${
                                    location.pathname.startsWith('/blog')
                                        ? 'text-[#2b3990]'
                                        : 'text-gray-900'
                                }`}
                            >
                                Blog
                            </Link>
                            <Link
                                to="/login"
                                className={`px-3 py-2 text-base font-medium hover:text-[#2b3990] transition-colors ${
                                    location.pathname === '/login'
                                        ? 'text-[#2b3990]'
                                        : 'text-gray-900'
                                }`}
                            >
                                Customer Login
                            </Link>
                        </div>
                    </div>

                    <div className="hidden sm:flex sm:items-center">
                        <a
                            href="mailto:rodrigo@peregrino.co"
                            className="ml-8 inline-flex items-center justify-center px-6 py-2.5 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#2b3990] hover:bg-[#232e73] transition-colors"
                        >
                            Contacto
                        </a>
                    </div>

                    <div className="flex items-center sm:hidden">
                        <button
                            onClick={toggleMenu}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-[#2b3990] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#2b3990] transition-colors"
                            aria-expanded={isMenuOpen}
                        >
                            <span className="sr-only">Abrir menú</span>
                            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
                <div className="pt-2 pb-4 space-y-1">
                    <Link
                        to="/"
                        className={`block px-3 py-2 text-base font-medium hover:bg-gray-50 hover:text-[#2b3990] transition-colors ${
                            location.pathname === '/'
                                ? 'bg-gray-50 text-[#2b3990]'
                                : 'text-gray-900'
                        }`}
                    >
                        Inicio
                    </Link>
                    <Link
                        to="/how-we-do-it"
                        className={`block px-3 py-2 text-base font-medium hover:bg-gray-50 hover:text-[#2b3990] transition-colors ${
                            location.pathname === '/how-we-do-it'
                                ? 'bg-gray-50 text-[#2b3990]'
                                : 'text-gray-900'
                        }`}
                    >
                        Cómo lo hacemos
                    </Link>
                    <Link
                        to="/services"
                        className={`block px-3 py-2 text-base font-medium hover:bg-gray-50 hover:text-[#2b3990] transition-colors ${
                            location.pathname === '/services'
                                ? 'bg-gray-50 text-[#2b3990]'
                                : 'text-gray-900'
                        }`}
                    >
                        Servicios
                    </Link>
                    <Link
                        to="/about"
                        className={`block px-3 py-2 text-base font-medium hover:bg-gray-50 hover:text-[#2b3990] transition-colors ${
                            location.pathname === '/about'
                                ? 'bg-gray-50 text-[#2b3990]'
                                : 'text-gray-900'
                        }`}
                    >
                        Nosotros
                    </Link>
                    {/* Nueva opción de Blog en móvil */}
                    <Link
                        to="/blog"
                        className={`block px-3 py-2 text-base font-medium hover:bg-gray-50 hover:text-[#2b3990] transition-colors ${
                            location.pathname.startsWith('/blog')
                                ? 'bg-gray-50 text-[#2b3990]'
                                : 'text-gray-900'
                        }`}
                    >
                        Blog
                    </Link>
                    <Link
                        to="/login"
                        className={`block px-3 py-2 text-base font-medium hover:bg-gray-50 hover:text-[#2b3990] transition-colors ${
                            location.pathname === '/login'
                                ? 'bg-gray-50 text-[#2b3990]'
                                : 'text-gray-900'
                        }`}
                    >
                        Customer Login
                    </Link>
                    <a
                        href="mailto:rodrigo@peregrino.co"
                        className="block w-full text-center px-4 py-2 mx-3 my-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#2b3990] hover:bg-[#232e73] transition-colors"
                    >
                        Contacto
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;