// src/pages/Blog.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Search, Calendar, User, Tag, Clock, ArrowRight, ChevronLeft, ChevronRight, Share2 } from 'lucide-react';
import Section from '../components/ui/Section';
import Container from '../components/ui/Container';
import { Heading, Paragraph, BrandHeading } from '../components/ui/Typography';
import { Row, Col, Grid } from '../components/ui/Grid';
import { Card, CardHeader, CardContent } from '../components/ui/Card';
import Button from '../components/ui/Button';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';
import { sampleBlogPosts, getCategoryName } from '../data/blogData';

// Componente principal de Blog
const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const { category } = useParams();
    const { tag } = useParams();

    useEffect(() => {
        // Si hay una categoría en la URL, seleccionarla
        if (category) {
            setSelectedCategory(category);
        }

        // Cargar datos del blog
        fetchBlogData();
    }, [category]);

    const fetchBlogData = async () => {
        setLoading(true);
        try {
            // Simulación de carga de datos
            // En producción, esto sería un fetch real a tu API
            setTimeout(() => {
                setPosts(sampleBlogPosts);
                // Extraer categorías únicas de los posts
                const uniqueCategories = [...new Set(sampleBlogPosts.map(post => post.category))];

                setCategories([
                    { id: 'all', name: 'Todos' },
                    ...uniqueCategories.map(cat => ({
                        id: cat,
                        name: getCategoryName(cat)
                    }))
                ]);

                setLoading(false);
            }, 500);
        } catch (error) {
            console.error('Error fetching blog data:', error);
            setLoading(false);
        }
    };

    // Filtrar posts según la categoría seleccionada y el término de búsqueda
    const filteredPosts = posts.filter(post => {
        const matchesCategory = selectedCategory === 'all' || post.category === selectedCategory;
        const matchesTag = !tag || (post.tags && post.tags.some(t => t.toLowerCase().replace(' ', '-') === tag));
        const matchesSearch = !searchQuery ||
            post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            post.excerpt.toLowerCase().includes(searchQuery.toLowerCase());

        return matchesCategory && matchesSearch && (tag ? matchesTag : true);
    });

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Hero Banner del Blog */}
            <section className="w-full bg-[#2b3990] py-16">
                <Container>
                    <div className="text-center">
                        <h1 className="text-4xl md:text-5xl font-bold text-white uppercase mb-4">
                            BLOG PEREGRINO
                        </h1>
                        <p className="text-xl text-white/80 max-w-3xl mx-auto">
                            Tendencias, estrategias y conocimientos sobre transformación digital para empresas colombianas
                        </p>
                    </div>
                </Container>
            </section>

            {/* Barra de búsqueda y filtros */}
            <Section bgColor="white" spacing="tight" className="border-b">
                <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                    {/* Buscador */}
                    <div className="w-full md:w-1/3">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Buscar en el blog..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full py-2 px-4 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2b3990] focus:border-transparent"
                            />
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                        </div>
                    </div>

                    {/* Filtros por categoría */}
                    <div className="flex flex-wrap gap-2 justify-center md:justify-end">
                        {categories.map(category => (
                            <button
                                key={category.id}
                                onClick={() => setSelectedCategory(category.id)}
                                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                                    selectedCategory === category.id
                                        ? 'bg-[#2b3990] text-white'
                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                }`}
                            >
                                {category.name}
                            </button>
                        ))}
                    </div>
                </div>
            </Section>

            {/* Contenido principal */}
            <Section spacing="normal">
                <div className="flex flex-col md:flex-row gap-10">
                    {/* Lista de Posts */}
                    <div className="w-full md:w-2/3">
                        {loading ? (
                            <div className="text-center py-10">
                                <p className="text-lg text-gray-600">Cargando artículos...</p>
                            </div>
                        ) : filteredPosts.length > 0 ? (
                            <div className="space-y-10">
                                {filteredPosts.map(post => (
                                    <BlogPostCard key={post.id} post={post} />
                                ))}

                                {/* Paginación */}

                            </div>
                        ) : (
                            <div className="text-center py-10">
                                <Alert>
                                    <AlertTitle>No se encontraron artículos</AlertTitle>
                                    <AlertDescription>
                                        Intenta con otra búsqueda o categoría diferente.
                                    </AlertDescription>
                                </Alert>
                            </div>
                        )}
                    </div>

                    {/* Sidebar */}
                    <div className="w-full md:w-1/3">
                        <BlogSidebar categories={categories} posts={posts} />
                    </div>
                </div>
            </Section>

            {/* Suscripción al Newsletter */}
            <Section bgColor="[#2b3990]" spacing="normal">
                <div className="text-center">
                    <Heading variant="h2" color="white" align="center" className="mb-4">
                        SUSCRÍBETE A NUESTRO NEWSLETTER
                    </Heading>
                    <Paragraph size="lg" color="white/80" align="center" className="max-w-2xl mx-auto mb-8">
                        Recibe en tu correo los mejores artículos, casos de éxito y tendencias sobre transformación digital para PYMEs colombianas.
                    </Paragraph>
                    <div className="max-w-md mx-auto">
                        <div className="flex flex-col sm:flex-row gap-3">
                            <input
                                type="email"
                                placeholder="Tu correo electrónico"
                                className="flex-grow py-3 px-4 rounded-md focus:outline-none"
                            />
                            <button className="bg-white text-[#2b3990] font-semibold py-3 px-6 rounded-md hover:bg-gray-100 transition-colors">
                                SUSCRIBIRME
                            </button>
                        </div>
                        <p className="text-white/70 text-sm mt-3">
                            No hacemos spam. Puedes cancelar tu suscripción en cualquier momento.
                        </p>
                    </div>
                </div>
            </Section>
        </div>
    );
};

// Componente de tarjeta de blog post
const BlogPostCard = ({ post }) => {
    return (
        <article className="bg-white rounded-lg shadow-sm overflow-hidden transition-shadow hover:shadow-md">
            <Link to={`/blog/${post.slug}`} className="block">
                <div className="aspect-video bg-gray-200 overflow-hidden">
                    <img
                        src={post.coverImage}
                        alt={post.title}
                        className="w-full h-full object-cover transition-transform hover:scale-105"
                    />
                </div>
            </Link>
            <div className="p-6">
                <div className="flex items-center gap-3 text-sm text-gray-500 mb-4">
                    <span className="inline-flex items-center">
                        <Calendar className="w-4 h-4 mr-1" />
                        {post.date}
                    </span>
                    <span className="inline-flex items-center">
                        <User className="w-4 h-4 mr-1" />
                        {post.author}
                    </span>
                    <span className="inline-flex items-center">
                        <Tag className="w-4 h-4 mr-1" />
                        {getCategoryName(post.category)}
                    </span>
                </div>

                <Link to={`/blog/${post.slug}`}>
                    <h2 className="text-2xl font-bold text-gray-900 mb-3 hover:text-[#2b3990] transition-colors">
                        {post.title}
                    </h2>
                </Link>

                <p className="text-gray-600 mb-4 line-clamp-3">
                    {post.excerpt}
                </p>

                <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500 flex items-center">
                        <Clock className="w-4 h-4 mr-1" />
                        {post.readTime} min de lectura
                    </span>
                    <Link
                        to={`/blog/${post.slug}`}
                        className="text-[#2b3990] font-medium flex items-center hover:underline"
                    >
                        Leer más <ArrowRight className="w-4 h-4 ml-1" />
                    </Link>
                </div>
            </div>
        </article>
    );
};

// Componente de sidebar del blog
const BlogSidebar = ({ categories, posts }) => {
    // Obtener los posts más populares
    const popularPosts = [...posts].sort((a, b) => b.views - a.views).slice(0, 4);

    return (
        <div className="space-y-8">
            {/* Categorías */}
            <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="text-xl font-bold mb-4">Categorías</h3>
                <ul className="space-y-2">
                    {categories.filter(cat => cat.id !== 'all').map(category => (
                        <li key={category.id}>
                            <Link
                                to={`/blog/categoria/${category.id}`}
                                className="flex justify-between items-center py-2 hover:text-[#2b3990] transition-colors"
                            >
                                <span>{category.name}</span>
                                <span className="bg-gray-100 text-gray-700 text-sm py-1 px-2 rounded-full">
                                    {posts.filter(post => post.category === category.id).length}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Posts populares */}
            <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="text-xl font-bold mb-4">Lo más leído</h3>
                <div className="space-y-4">
                    {popularPosts.map(post => (
                        <div key={post.id} className="flex gap-3">
                            <div className="w-20 h-20 flex-shrink-0 rounded-md overflow-hidden bg-gray-200">
                                <img
                                    src={post.coverImage}
                                    alt={post.title}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div>
                                <Link
                                    to={`/blog/${post.slug}`}
                                    className="font-medium line-clamp-2 hover:text-[#2b3990] transition-colors"
                                >
                                    {post.title}
                                </Link>
                                <div className="text-sm text-gray-500 mt-1 flex items-center">
                                    <Calendar className="w-3 h-3 mr-1" />
                                    {post.date}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Tags */}
            <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="text-xl font-bold mb-4">Tags</h3>
                <div className="flex flex-wrap gap-2">
                    {['Transformación Digital', 'IA', 'PYMEs', 'Colombia', 'E-commerce',
                        'ROI', 'Automatización', 'ChatGPT', 'Estrategia', 'Datos'].map(tag => (
                        <Link
                            key={tag}
                            to={`/blog/tag/${tag.toLowerCase().replace(' ', '-')}`}
                            className="bg-gray-100 hover:bg-[#2b3990] hover:text-white px-3 py-1 rounded-full text-sm transition-colors"
                        >
                            {tag}
                        </Link>
                    ))}
                </div>
            </div>

            {/* CTA */}
            <div className="bg-[#2b3990]/10 rounded-lg p-6 border border-[#2b3990]/20">
                <h3 className="text-xl font-bold text-[#2b3990] mb-3">¿Necesitas ayuda con tu transformación digital?</h3>
                <p className="text-gray-700 mb-4">
                    Agenda una visita y descubre cómo podemos ayudarte a impulsar tu negocio con soluciones digitales a medida.
                </p>
                <Button
                    variant="primary"
                    href="mailto:rodrigo@peregrino.co"
                    fullWidth
                >
                    Agendar consultoría
                </Button>
            </div>
        </div>
    );
};

export default Blog;