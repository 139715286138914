import React, { useState, useRef, useEffect } from 'react';
import { MessageCircle, X, Send, ArrowRight } from 'lucide-react';

const ChatWidget = () => {
    // Estados para el chat
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [minimized, setMinimized] = useState(false);

    // Referencias para manipular el DOM
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);

    // Auto-scroll cuando llegan nuevos mensajes
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Focus en el input cuando se abre el chat
    useEffect(() => {
        if (isOpen && !minimized && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isOpen, minimized]);

    // Función para desplazarse al final de los mensajes
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Abrir/cerrar el chat
    const toggleChat = () => {
        const newIsOpen = !isOpen;
        setIsOpen(newIsOpen);

        // Si estamos abriendo el chat y está vacío, agregar mensaje de bienvenida
        if (newIsOpen && messages.length === 0) {
            setMessages([{
                id: Date.now(),
                sender: 'bot',
                text: '¡Hola! Soy el asistente de Peregrino. ¿En qué puedo ayudarte?'
            }]);
        }

        // Si estamos abriendo y estaba minimizado, desminimizarlo
        if (newIsOpen && minimized) {
            setMinimized(false);
        }
    };

    // Minimizar/maximizar el chat
    const toggleMinimize = (e) => {
        e.stopPropagation();
        setMinimized(!minimized);
    };

    // Enviar mensaje
    const sendMessage = async () => {
        if (userMessage.trim() === '') return;

        // Agregar mensaje del usuario a la conversación
        const newUserMessage = {
            id: Date.now(),
            sender: 'user',
            text: userMessage,
            timestamp: new Date().toISOString()
        };

        setMessages(prev => [...prev, newUserMessage]);
        setUserMessage('');
        setIsTyping(true);

        try {
            // Mantener la conexión API existente
            const response = await fetch('https://nexustour-c9edc0e1332b.herokuapp.com/message', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    message: newUserMessage.text,
                    userId: 'web-user'
                })
            });

            const data = await response.json();

            // Agregar respuesta del bot
            const botMessage = {
                id: Date.now(),
                sender: 'bot',
                text: data.response || '(Sin respuesta)',
                timestamp: new Date().toISOString()
            };

            setMessages(prev => [...prev, botMessage]);
        } catch (error) {
            console.error('Error:', error);

            // Mensaje de error
            const errorMessage = {
                id: Date.now(),
                sender: 'bot',
                text: `Lo siento, ocurrió un problema: ${error.message || 'No se pudo conectar con el servidor.'}`,
                timestamp: new Date().toISOString(),
                isError: true
            };

            setMessages(prev => [...prev, errorMessage]);
        } finally {
            setIsTyping(false);
        }
    };

    // Manejar teclas (Enter para enviar)
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    // Formato de tiempo para los mensajes
    const formatTime = (timestamp) => {
        const date = timestamp ? new Date(timestamp) : new Date();
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <div className="fixed bottom-6 right-6 z-50 flex flex-col items-end">
            {/* Chat expandido */}
            {isOpen && (
                <div className="bg-white rounded-lg shadow-xl w-full max-w-sm mb-4 flex flex-col overflow-hidden border border-gray-200">
                    {/* Header */}
                    <div
                        className="bg-peregrino-blue text-white p-3 flex justify-between items-center cursor-pointer"
                        onClick={toggleMinimize}
                    >
                        <div className="flex items-center gap-2">
                            <div className="w-8 h-8 rounded-full bg-white/20 flex items-center justify-center">
                                <MessageCircle className="w-4 h-4" />
                            </div>
                            <div>
                                <h3 className="text-sm font-medium">Asistente Peregrino</h3>
                                {!minimized && <p className="text-xs opacity-80">Estamos para ayudarte</p>}
                            </div>
                        </div>
                        <div className="flex items-center gap-1">
                            <X
                                className="w-5 h-5 hover:opacity-70 transition-opacity"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsOpen(false);
                                }}
                            />
                        </div>
                    </div>

                    {/* Contenido del chat (visible solo si no está minimizado) */}
                    {!minimized && (
                        <>
                            {/* Área de mensajes */}
                            <div className="flex-1 p-3 overflow-y-auto h-80 bg-gray-50">
                                <div className="space-y-3">
                                    {messages.map((msg) => (
                                        <div
                                            key={msg.id}
                                            className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                                        >
                                            <div
                                                className={`max-w-[80%] rounded-lg px-3 py-2 text-sm ${
                                                    msg.sender === 'user'
                                                        ? 'bg-peregrino-blue text-white'
                                                        : msg.isError
                                                            ? 'bg-red-50 border border-red-200 text-red-600'
                                                            : 'bg-white border border-gray-200 text-gray-800'
                                                }`}
                                            >
                                                <p>{msg.text}</p>
                                                <span className={`text-xs mt-1 block ${
                                                    msg.sender === 'user' ? 'text-white/70 text-right' : 'text-gray-500 text-right'
                                                }`}>
                          {formatTime(msg.timestamp)}
                        </span>
                                            </div>
                                        </div>
                                    ))}

                                    {/* Indicador de "escribiendo..." */}
                                    {isTyping && (
                                        <div className="flex justify-start">
                                            <div className="bg-white border border-gray-200 text-gray-500 rounded-lg px-3 py-2 text-sm">
                                                <div className="flex items-center gap-1">
                                                    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                                                    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                                                    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Referencia para auto-scroll */}
                                    <div ref={messagesEndRef} />
                                </div>
                            </div>

                            {/* Área de input */}
                            <div className="p-3 border-t border-gray-200 bg-white">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        sendMessage();
                                    }}
                                    className="flex items-end gap-2"
                                >
                                    <div className="flex-1 relative">
                    <textarea
                        ref={inputRef}
                        value={userMessage}
                        onChange={(e) => setUserMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Escribe tu mensaje..."
                        className="w-full p-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-peregrino-blue focus:border-transparent resize-none"
                        rows={1}
                        style={{ minHeight: '40px', maxHeight: '120px' }}
                    />
                                        <div className="absolute right-2 bottom-2 text-xs text-gray-400 pointer-events-none">
                                            Enter para enviar
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={userMessage.trim() === ''}
                                        className={`flex-shrink-0 p-2 rounded-full ${
                                            userMessage.trim() === ''
                                                ? 'bg-gray-200 text-gray-400'
                                                : 'bg-peregrino-blue text-white hover:bg-peregrino-blue/90'
                                        } transition-colors`}
                                    >
                                        <Send className="w-4 h-4" />
                                    </button>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            )}

            {/* Botón de chat */}
            <button
                onClick={toggleChat}
                className={`${
                    isOpen ? 'bg-gray-700' : 'bg-peregrino-blue'
                } text-white rounded-full p-3 shadow-lg hover:opacity-90 transition-all flex items-center gap-2 group`}
            >
                {isOpen ? (
                    <X className="w-6 h-6" />
                ) : (
                    <>
                        <MessageCircle className="w-6 h-6" />
                        <span className="max-w-0 overflow-hidden group-hover:max-w-xs transition-all duration-500 ease-in-out whitespace-nowrap">
              Chat con nosotros
            </span>
                        <ArrowRight className="w-0 h-4 group-hover:w-4 transition-all duration-500 opacity-0 group-hover:opacity-100" />
                    </>
                )}
            </button>
        </div>
    );
};

export default ChatWidget;