// src/pages/LoginPage.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Lock, AlertCircle, ArrowRight, Loader } from 'lucide-react';
import { Alert, AlertDescription } from '../components/ui/alert';
import Container from '../components/ui/Container';
import Section from '../components/ui/Section';
import { Heading, Paragraph } from '../components/ui/Typography';
import Button from '../components/ui/Button';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            // Simulate network delay for demo purposes (remove this in production)
            await new Promise(resolve => setTimeout(resolve, 800));

            const response = await fetch('https://api.peregrino.co/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include', // Include cookies in the request
            });
            const data = await response.json();

            if (data.success) {
                window.location.href = `https://${data.subdomain}`; // Redirect to subdomain
            } else {
                setError(data.message || 'Credenciales inválidas. Por favor intenta de nuevo.');
            }
        } catch (err) {
            setError('Ha ocurrido un error. Por favor intenta de nuevo más tarde.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-[calc(100vh-80px)] bg-gray-50 py-12">
            <Container>
                <Section bgColor="white" className="rounded-xl shadow-md max-w-md mx-auto">
                    <div className="text-center mb-8">
                        <Heading variant="h1" align="center" className="mb-3 text-[#2b3990]">
                            Portal de Clientes
                        </Heading>
                        <Paragraph align="center" color="gray-600">
                            Accede a tu cuenta para gestionar tus proyectos y servicios
                        </Paragraph>
                    </div>

                    {error && (
                        <Alert variant="destructive" className="mb-6">
                            <div className="flex items-start">
                                <AlertCircle className="w-4 h-4 mr-2 mt-0.5 flex-shrink-0" />
                                <AlertDescription>{error}</AlertDescription>
                            </div>
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <div className="space-y-5">
                            <div>
                                <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                                    Usuario
                                </label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <User className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="username"
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                        className="pl-10 w-full py-2 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2b3990] focus:border-transparent"
                                        placeholder="Ingresa tu usuario"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                                    Contraseña
                                </label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <Lock className="h-5 w-5 text-gray-400" />
                                    </div>
                                    <input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        className="pl-10 w-full py-2 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2b3990] focus:border-transparent"
                                        placeholder="Ingresa tu contraseña"
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 text-[#2b3990] focus:ring-[#2b3990] border-gray-300 rounded"
                                    />
                                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                                        Recordarme
                                    </label>
                                </div>

                                <div className="text-sm">
                                    <a href="#" className="font-medium text-[#2b3990] hover:text-[#232e73]">
                                        ¿Olvidaste tu contraseña?
                                    </a>
                                </div>
                            </div>

                            <Button
                                type="submit"
                                variant="primary"
                                disabled={loading}
                                fullWidth
                                className="py-2.5 mt-2"
                            >
                                {loading ? (
                                    <div className="flex items-center justify-center">
                                        <Loader className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" />
                                        <span>Iniciando sesión...</span>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-center">
                                        <span>Iniciar Sesión</span>
                                        <ArrowRight className="ml-2 h-4 w-4" />
                                    </div>
                                )}
                            </Button>
                        </div>
                    </form>

                    <div className="mt-8 pt-6 border-t border-gray-200 text-center">
                        <p className="text-sm text-gray-600">
                            ¿No tienes una cuenta?{' '}
                            <a href="mailto:rodrigo@peregrino.co" className="font-medium text-[#2b3990] hover:text-[#232e73]">
                                Contáctanos
                            </a>
                        </p>
                    </div>
                </Section>
            </Container>
        </div>
    );
};

export default LoginPage;