// src/components/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, Phone } from 'lucide-react';
import logoPeregrino from '../assets/images/peregrino-logo-black.png';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-50 border-t border-gray-200">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Logo y descripción */}
                    <div className="flex flex-col items-start">
                        <img
                            src={logoPeregrino}
                            alt="Peregrino"
                            className="h-12 w-auto mb-4"
                        />
                        <p className="text-gray-600 mt-2 text-sm max-w-xs">
                            Transformamos negocios a través de soluciones digitales inteligentes y enfocadas en resultados.
                        </p>
                    </div>

                    {/* Links de navegación */}
                    <div className="md:text-center">
                        <h3 className="text-base font-semibold text-gray-900 tracking-wider uppercase mb-4">
                            Navegación
                        </h3>
                        <ul className="space-y-2">
                            <li>
                                <Link to="/" className="text-gray-600 hover:text-[#2b3990] transition-colors">
                                    Inicio
                                </Link>
                            </li>
                            <li>
                                <Link to="/how-we-do-it" className="text-gray-600 hover:text-[#2b3990] transition-colors">
                                    Cómo lo hacemos
                                </Link>
                            </li>
                            <li>
                                <Link to="/services" className="text-gray-600 hover:text-[#2b3990] transition-colors">
                                    Servicios
                                </Link>
                            </li>
                            <li>
                                <Link to="/about" className="text-gray-600 hover:text-[#2b3990] transition-colors">
                                    Nosotros
                                </Link>
                            </li>
                        </ul>
                    </div>

                    {/* Contacto */}
                    <div className="md:text-right">
                        <h3 className="text-base font-semibold text-gray-900 tracking-wider uppercase mb-4">
                            Contacto
                        </h3>
                        <div className="space-y-3">
                            <p className="text-gray-600">Peregrino Colombia SAS</p>
                            <a
                                href="tel:+573162892694"
                                className="block text-gray-600 hover:text-[#2b3990] transition-colors flex items-center justify-end"
                            >
                                <Phone className="h-4 w-4 mr-2" />
                                +57 316 289 2694
                            </a>
                            <a
                                href="mailto:rodrigo@peregrino.co"
                                className="block text-gray-600 hover:text-[#2b3990] transition-colors flex items-center justify-end"
                            >
                                <Mail className="h-4 w-4 mr-2" />
                                rodrigo@peregrino.co
                            </a>
                        </div>
                    </div>
                </div>

                {/* Copyright y términos */}
                <div className="mt-12 pt-8 border-t border-gray-200">
                    <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                        <p className="text-sm text-gray-500">
                            © {currentYear} Peregrino Colombia SAS. Todos los derechos reservados.
                        </p>
                        <p className="text-sm text-gray-500 mt-2 md:mt-0">
                            Protegido bajo las leyes de propiedad intelectual de Colombia.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;