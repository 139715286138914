
import React, { useState, useEffect, useRef } from 'react';
import {
    BrainCircuit,
    BarChart3,
    Lightbulb,
    ShoppingCart,
    Check,
    ArrowRight,
    ArrowUpRight,
    PlusCircle
} from 'lucide-react';

const ServicesSection = () => {
    const [activeService, setActiveService] = useState(null);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const servicesRef = useRef(null);

    const services = [
        {
            id: 'consultoria',
            title: 'Consultoría Estratégica Digital',
            shortTitle: 'Consultoría',
            icon: <Lightbulb className="w-6 h-6" />,
            description: 'Orientación experta para definir el rumbo de transformación digital de tu negocio.',
            examples: [
                {
                    title: 'Diagnóstico de Madurez Digital',
                    description: 'Evaluación profunda del estado actual de digitalización de la empresa, identificando oportunidades prioritarias y definiendo una hoja de ruta clara.',
                    features: [
                        'Análisis de capacidades digitales actuales',
                        'Benchmark competitivo en el sector',
                        'Identificación de oportunidades de mejora',
                        'Hoja de ruta con acciones priorizadas'
                    ]
                },
                {
                    title: 'Arquitectura de Soluciones',
                    description: 'Diseño de la estructura tecnológica ideal para soportar los objetivos de negocio, considerando sistemas existentes y necesidades futuras.',
                    features: [
                        'Diseño de arquitectura objetivo',
                        'Plan de evolución por fases',
                        'Evaluación y selección de tecnologías',
                        'Modelo de gobierno y operación'
                    ]
                },
                {
                    title: 'Estrategia de Datos',
                    description: 'Definición del enfoque para convertir los datos en activos estratégicos, abarcando arquitectura, gobernanza y monetización.',
                    features: [
                        'Modelo de gobernanza de datos',
                        'Arquitectura de datos e integración',
                        'Estrategia de calidad y seguridad',
                        'Plan de aprovechamiento y monetización'
                    ]
                }
            ]
        },
        {
            id: 'comercio-digital',
            title: 'Desarrollos Tecnológicos para Comercio Digital',
            shortTitle: 'Comercio Digital',
            icon: <ShoppingCart className="w-6 h-6" />,
            description: 'Soluciones tecnológicas especializadas para optimizar procesos comerciales y de venta.',
            examples: [
                {
                    title: 'Sistema Integrado de Gestión Comercial',
                    description: 'Plataforma centralizada que conecta marketing, ventas y operaciones para ofrecer una visión 360° del cliente y automatizar flujos de trabajo críticos.',
                    features: [
                        'CRM integrado con canales digitales',
                        'Pipeline visual con analytics avanzado',
                        'Automatización de seguimientos',
                        'Documentación digital centralizada'
                    ]
                },
                {
                    title: 'Plataforma de Ventas Omnicanal',
                    description: 'Sistema que unifica la experiencia de venta a través de múltiples canales (web, móvil, WhatsApp) manteniendo la consistencia y optimizando la conversión.',
                    features: [
                        'Sincronización en tiempo real entre canales',
                        'Historial unificado de interacciones',
                        'Carrito de compras persistente',
                        'Gestión centralizada de inventario y precios'
                    ]
                },
                {
                    title: 'Automatización de Ciclo de Venta',
                    description: 'Solución que digitaliza y automatiza el proceso completo desde la prospección hasta el cierre y seguimiento post-venta.',
                    features: [
                        'Calificación automática de leads',
                        'Sistema de cotización y propuestas',
                        'Gestión digital de contratos',
                        'Seguimiento automatizado post-venta'
                    ]
                }
            ]
        },
        {
            id: 'ai-aplicada',
            title: 'IA Aplicada',
            shortTitle: 'IA Aplicada',
            icon: <BrainCircuit className="w-6 h-6" />,
            description: 'Implementación práctica de inteligencia artificial en procesos de negocio para generar resultados tangibles.',
            examples: [
                {
                    title: 'Asistentes Virtuales Comerciales',
                    description: 'Agentes inteligentes especializados en atención al cliente y ventas, capaces de responder consultas 24/7, calificar leads y guiar el proceso de compra.',
                    features: [
                        'Respuestas personalizadas basadas en histórico',
                        'Calificación automática de oportunidades',
                        'Escalamiento inteligente a asesores humanos',
                        'Seguimiento y nurturing automatizado'
                    ]
                },
                {
                    title: 'Optimización Inteligente de Procesos',
                    description: 'Aplicación de IA para identificar y eliminar ineficiencias en flujos de trabajo, automatizando decisiones rutinarias y optimizando recursos.',
                    features: [
                        'Identificación automática de cuellos de botella',
                        'Recomendaciones de optimización basadas en datos',
                        'Automatización de decisiones repetitivas',
                        'Medición continua de mejoras'
                    ]
                },
                {
                    title: 'Personalización Avanzada',
                    description: 'Sistemas que utilizan IA para crear experiencias altamente personalizadas para clientes, incrementando engagement y conversión.',
                    features: [
                        'Segmentación dinámica basada en comportamiento',
                        'Recomendaciones de productos/contenidos personalizados',
                        'Comunicaciones adaptadas a preferencias individuales',
                        'Optimización continua basada en resultados'
                    ]
                }
            ]
        },
        {
            id: 'bi-analytics',
            title: 'Business Intelligence y Analytics',
            shortTitle: 'BI & Analytics',
            icon: <BarChart3 className="w-6 h-6" />,
            description: 'Transformación de datos en insights accionables para impulsar decisiones estratégicas y operativas.',
            examples: [
                {
                    title: 'Dashboards Ejecutivos Integrados',
                    description: 'Sistemas de visualización en tiempo real de KPIs críticos con alertas inteligentes y capacidades predictivas para anticipar tendencias.',
                    features: [
                        'Visualizaciones personalizadas por rol y nivel',
                        'Integración de múltiples fuentes de datos',
                        'Alertas automáticas ante desviaciones',
                        'Análisis comparativo histórico y predictivo'
                    ]
                },
                {
                    title: 'Analytics Predictivo de Ventas',
                    description: 'Plataforma que utiliza modelos avanzados para predecir comportamientos de venta y optimizar la asignación de recursos comerciales.',
                    features: [
                        'Forecasting preciso por producto/segmento/región',
                        'Identificación temprana de oportunidades',
                        'Optimización de mix de productos',
                        'Simulación de escenarios comerciales'
                    ]
                },
                {
                    title: 'Análisis de Experiencia de Cliente',
                    description: 'Sistema para medir, analizar y optimizar cada punto de contacto con el cliente, mejorando satisfacción y lealtad.',
                    features: [
                        'Mapeo de journey completo del cliente',
                        'Identificación de puntos de fricción',
                        'Correlación entre experiencia y resultados',
                        'Recomendaciones de optimización priorizadas'
                    ]
                }
            ]
        }
    ];

    const handleServiceClick = (id) => {
        setIsTransitioning(true);

        setTimeout(() => {
            setActiveService(id === activeService ? null : id);
            setIsTransitioning(false);
        }, 300);

        if (id !== activeService && servicesRef.current) {
            servicesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Get active service data
    const activeServiceData = services.find(service => service.id === activeService);

    return (
        <div className="w-full py-16 bg-white" ref={servicesRef}>
            <div className="max-w-6xl mx-auto px-6">
                <div className="text-center mb-12">
                    <h2 className="text-4xl font-bold mb-4 text-[#2b3990]">
                        NUESTROS SERVICIOS
                    </h2>
                    <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                        Soluciones digitales diseñadas para transformar tu negocio
                    </p>
                </div>

                {/* Apple-style Horizontal Scrolling Menu */}
                <div className="mb-12 relative">
                    <div className="overflow-x-auto hide-scrollbar py-2">
                        <div className="flex gap-2 justify-center min-w-max mx-auto">
                            {services.map((service) => (
                                <button
                                    key={service.id}
                                    onClick={() => handleServiceClick(service.id)}
                                    className={`relative px-6 py-3 rounded-full text-sm font-medium transition-all duration-300 ${
                                        activeService === service.id
                                            ? 'bg-[#2b3990] text-white shadow-md'
                                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                    }`}
                                >
                  <span className="flex items-center gap-2">
                    <span>{service.icon}</span>
                    <span>{service.shortTitle || service.title}</span>
                  </span>
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Gradient Fade on the sides */}
                    <div className="absolute top-0 left-0 h-full w-12 bg-gradient-to-r from-white to-transparent pointer-events-none md:hidden"></div>
                    <div className="absolute top-0 right-0 h-full w-12 bg-gradient-to-l from-white to-transparent pointer-events-none md:hidden"></div>
                </div>

                {/* Service Cards - Visible when no service is selected */}
                <div className={`grid grid-cols-1 md:grid-cols-2 gap-8 mb-16 transition-all duration-500 ${
                    activeService || isTransitioning ? 'opacity-0 h-0 overflow-hidden' : 'opacity-100'
                }`}>
                    {services.map((service) => (
                        <div
                            key={service.id}
                            onClick={() => handleServiceClick(service.id)}
                            className="bg-white rounded-2xl p-8 shadow-sm hover:shadow-md transition-all cursor-pointer border border-gray-100 group"
                        >
                            <div className="flex items-center gap-3 mb-4">
                                <div className="w-12 h-12 rounded-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990]">
                                    {service.icon}
                                </div>
                                <h3 className="text-xl font-semibold text-gray-900">{service.title}</h3>
                            </div>

                            <p className="text-gray-600 mb-6">{service.description}</p>

                            <div className="flex flex-wrap gap-2 mb-6">
                                {service.examples.map((example, idx) => (
                                    <span key={idx} className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm font-medium">
                    {example.title}
                  </span>
                                ))}
                            </div>

                            <button className="inline-flex items-center gap-1 text-[#2b3990] font-medium group-hover:gap-2 transition-all">
                                Ver detalles <ArrowRight className="w-4 h-4" />
                            </button>
                        </div>
                    ))}
                </div>

                {/* Active Service Detail */}
                <div className={`transition-all duration-500 ${
                    !activeService && !isTransitioning ? 'opacity-0 h-0 overflow-hidden' : 'opacity-100'
                }`}>
                    {activeServiceData && (
                        <div className="bg-white rounded-2xl overflow-hidden shadow-md mb-16">
                            {/* Header */}
                            <div className="bg-[#2b3990] text-white p-8">
                                <button
                                    onClick={() => handleServiceClick(activeService)}
                                    className="mb-6 inline-flex items-center gap-2 bg-white/10 hover:bg-white/20 px-4 py-2 rounded-full text-sm font-medium transition-all"
                                >
                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Volver a servicios
                                </button>

                                <div className="flex items-center gap-4">
                                    <div className="w-14 h-14 rounded-full bg-white/20 flex items-center justify-center">
                                        {activeServiceData.icon}
                                    </div>
                                    <div>
                                        <h3 className="text-2xl font-bold">{activeServiceData.title}</h3>
                                        <p className="text-white/80 mt-1 max-w-2xl">{activeServiceData.description}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Examples Grid */}
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 divide-y lg:divide-y-0 lg:divide-x divide-gray-100">
                                {activeServiceData.examples.map((example, idx) => (
                                    <div key={idx} className="p-8 hover:bg-gray-50 transition-colors">
                                        <div className="flex flex-col h-full">
                                            <span className="text-sm font-medium text-[#2b3990] uppercase tracking-wider mb-2">Ejemplo {idx + 1}</span>
                                            <h4 className="text-xl font-semibold mb-3 text-gray-900">{example.title}</h4>
                                            <p className="text-gray-600 mb-6 flex-grow">{example.description}</p>

                                            <div className="space-y-3 mb-6">
                                                {example.features.map((feature, fidx) => (
                                                    <div key={fidx} className="flex items-start gap-3">
                                                        <div className="h-6 w-6 rounded-full bg-[#2b3990]/10 flex items-center justify-center flex-shrink-0 mt-0.5">
                                                            <Check className="w-3 h-3 text-[#2b3990]" />
                                                        </div>
                                                        <p className="text-gray-700 text-sm">{feature}</p>
                                                    </div>
                                                ))}
                                            </div>

                                            <button className="inline-flex items-center gap-1.5 text-[#2b3990] text-sm font-medium hover:gap-2.5 transition-all self-start mt-auto group">
                                                <span>Más información</span>
                                                <ArrowUpRight className="w-4 h-4 group-hover:scale-110 transition-transform" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* "Cómo trabajamos" Section with Apple-inspired Design */}
                <div className="rounded-2xl overflow-hidden shadow-md mb-8">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="bg-[#2b3990] p-8 flex flex-col justify-center">
                            <h3 className="text-2xl font-bold text-white mb-6">Cómo trabajamos</h3>
                            <p className="text-white/90 mb-8">
                                Aplicamos un enfoque estructurado pero flexible para asegurar resultados tangibles desde las primeras etapas.
                            </p>
                            <button className="bg-white text-[#2b3990] px-6 py-3 rounded-full font-medium shadow-sm hover:shadow-md transition-all inline-flex items-center gap-2 w-fit">
                                Conoce nuestra metodología <ArrowRight className="w-4 h-4" />
                            </button>
                        </div>

                        <div className="bg-white p-8">
                            <div className="space-y-8">
                                <div className="flex items-start gap-4">
                                    <div className="w-10 h-10 rounded-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990] text-lg font-semibold flex-shrink-0">
                                        1
                                    </div>
                                    <div>
                                        <h4 className="font-semibold text-lg mb-1">Diagnóstico Profundo</h4>
                                        <p className="text-gray-600">Comprendemos a fondo tu negocio y objetivos antes de proponer soluciones</p>
                                    </div>
                                </div>

                                <div className="flex items-start gap-4">
                                    <div className="w-10 h-10 rounded-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990] text-lg font-semibold flex-shrink-0">
                                        2
                                    </div>
                                    <div>
                                        <h4 className="font-semibold text-lg mb-1">Integración Estratégica</h4>
                                        <p className="text-gray-600">Diseñamos soluciones que aprovechan tus sistemas actuales y potencian tu estrategia</p>
                                    </div>
                                </div>

                                <div className="flex items-start gap-4">
                                    <div className="w-10 h-10 rounded-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990] text-lg font-semibold flex-shrink-0">
                                        3
                                    </div>
                                    <div>
                                        <h4 className="font-semibold text-lg mb-1">Implementación Gradual</h4>
                                        <p className="text-gray-600">Ejecutamos de forma ágil, generando valor desde el inicio y adaptando según resultados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
        </div>
    );
};

export default ServicesSection;