// src/components/ui/Typography.jsx
import React from 'react';

/**
 * Componente de título responsivo para mantener consistencia tipográfica
 * @param {ReactNode} children - Texto del título
 * @param {string} variant - Variante de título (h1, h2, h3, h4, h5, h6)
 * @param {string} color - Color del texto (puede ser un color CSS válido o clase text- de Tailwind)
 * @param {string} align - Alineación del texto (left, center, right)
 * @param {string} className - Clases adicionales para personalización
 */
export const Heading = ({
                            children,
                            variant = 'h2',
                            color = 'gray-900',
                            align = 'left',
                            className = ''
                        }) => {
    const variantClasses = {
        h1: 'text-3xl sm:text-4xl md:text-5xl font-bold',
        h2: 'text-2xl sm:text-3xl font-bold',
        h3: 'text-xl sm:text-2xl font-semibold',
        h4: 'text-lg sm:text-xl font-semibold',
        h5: 'text-base sm:text-lg font-medium',
        h6: 'text-sm sm:text-base font-medium',
    };

    const alignClasses = {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
    };

    // Determinar si color es una clase de Tailwind o un color CSS
    const colorClass = color.startsWith('text-') ? color : `text-${color}`;

    const Component = variant;

    return (
        <Component className={`${variantClasses[variant]} ${colorClass} ${alignClasses[align]} ${className}`}>
            {children}
        </Component>
    );
};

/**
 * Componente de párrafo responsivo para mantener consistencia tipográfica
 * @param {ReactNode} children - Texto del párrafo
 * @param {string} size - Tamaño del texto (sm, base, lg)
 * @param {string} color - Color del texto (puede ser un color CSS válido o clase text- de Tailwind)
 * @param {string} align - Alineación del texto (left, center, right)
 * @param {string} className - Clases adicionales para personalización
 */
export const Paragraph = ({
                              children,
                              size = 'base',
                              color = 'gray-700',
                              align = 'left',
                              className = ''
                          }) => {
    const sizeClasses = {
        xs: 'text-xs sm:text-sm',
        sm: 'text-sm sm:text-base',
        base: 'text-base',
        lg: 'text-base sm:text-lg',
        xl: 'text-lg sm:text-xl',
    };

    const alignClasses = {
        left: 'text-left',
        center: 'text-center',
        right: 'text-right',
    };

    // Determinar si color es una clase de Tailwind o un color CSS
    const colorClass = color.startsWith('text-') ? color : `text-${color}`;

    return (
        <p className={`${sizeClasses[size]} ${colorClass} ${alignClasses[align]} ${className}`}>
            {children}
        </p>
    );
};

/**
 * Componente de texto de marca para Peregrino, usando colores y estilos de marca
 * @param {ReactNode} children - Texto a mostrar
 * @param {string} type - Tipo de texto de marca (primary, secondary)
 * @param {string} className - Clases adicionales para personalización
 */
export const BrandHeading = ({
                                 children,
                                 type = 'primary',
                                 className = ''
                             }) => {
    const typeClasses = {
        primary: 'text-[#2b3990] font-bold',
        secondary: 'text-white font-bold',
    };

    return (
        <h2 className={`text-2xl sm:text-3xl uppercase ${typeClasses[type]} ${className}`}>
            {children}
        </h2>
    );
};