// src/pages/admin/BlogAdmin.jsx
import React, { useState, useRef } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const BlogAdmin = () => {
    const [markdownInput, setMarkdownInput] = useState('');
    const [previewHtml, setPreviewHtml] = useState('');
    const [postMetadata, setPostMetadata] = useState({
        title: '',
        excerpt: '',
        category: 'transformacion-digital',
        tags: '',
        author: 'Rodrigo De Angulo',
        coverImage: 'https://via.placeholder.com/800x450'
    });
    const [showPreview, setShowPreview] = useState(false);
    const [jsonOutput, setJsonOutput] = useState('');
    const fileInputRef = useRef(null);

    // Lista de categorías disponibles
    const categories = [
        { id: 'transformacion-digital', name: 'Transformación Digital' },
        { id: 'inteligencia-artificial', name: 'Inteligencia Artificial' },
        { id: 'ecommerce', name: 'E-Commerce' },
        { id: 'estrategia', name: 'Estrategia Digital' },
        { id: 'casos-exito', name: 'Casos de Éxito' }
    ];

    // Manejar cambios en los campos de metadata
    const handleMetadataChange = (e) => {
        const { name, value } = e.target;
        setPostMetadata({
            ...postMetadata,
            [name]: value
        });
    };

    // Convertir Markdown a HTML para la vista previa
    const generatePreview = () => {
        try {
            // Generar HTML desde Markdown
            const htmlContent = DOMPurify.sanitize(marked.parse(markdownInput));
            setPreviewHtml(htmlContent);
            setShowPreview(true);
        } catch (error) {
            console.error('Error al generar la vista previa:', error);
            alert('Error al generar la vista previa. Verifica el formato Markdown.');
        }
    };

    // Generar el objeto JSON listo para usar en blogData.js
    const generateJsonOutput = () => {
        const slug = postMetadata.title
            .toLowerCase()
            .replace(/[^\w\s]/gi, '')
            .replace(/\s+/g, '-');

        const tagsArray = postMetadata.tags
            .split(',')
            .map(tag => tag.trim())
            .filter(tag => tag);

        const postObject = {
            id: Date.now(), // Usar timestamp como ID temporal
            title: postMetadata.title,
            slug,
            excerpt: postMetadata.excerpt,
            content: DOMPurify.sanitize(marked.parse(markdownInput)),
            category: postMetadata.category,
            tags: tagsArray,
            date: new Date().toLocaleDateString('es-CO', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            }),
            author: postMetadata.author,
            authorImage: 'https://via.placeholder.com/150',
            coverImage: postMetadata.coverImage,
            readTime: Math.ceil(markdownInput.split(/\s+/).length / 200) || 1,
            views: 0
        };

        // Convertir a cadena JSON formateada
        const jsonString = JSON.stringify(postObject, null, 2);
        setJsonOutput(jsonString);
        setShowPreview(false);
    };

    // Cargar archivo Markdown
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;

            // Procesar frontmatter si existe
            const frontmatterRegex = /---\s*([\s\S]*?)\s*---/;
            const match = content.match(frontmatterRegex);

            if (match) {
                const frontmatter = match[1];
                const markdownContent = content.replace(frontmatterRegex, '').trim();

                // Extraer metadata del frontmatter
                const metadata = {};
                frontmatter.split('\n').forEach(line => {
                    const parts = line.split(':');
                    if (parts.length >= 2) {
                        const key = parts[0].trim();
                        const value = parts.slice(1).join(':').trim();

                        if (key === 'tags') {
                            metadata[key] = value.replace(/[\[\]']/g, '');
                        } else {
                            metadata[key] = value;
                        }
                    }
                });

                // Actualizar estado con la metadata extraída
                setPostMetadata({
                    title: metadata.title || '',
                    excerpt: metadata.excerpt || '',
                    category: metadata.category || 'transformacion-digital',
                    tags: metadata.tags || '',
                    author: metadata.author || 'Rodrigo De Angulo',
                    coverImage: metadata.coverImage || 'https://via.placeholder.com/800x450'
                });

                setMarkdownInput(markdownContent);
            } else {
                // Si no hay frontmatter, cargar todo como contenido
                setMarkdownInput(content);
            }
        };
        reader.readAsText(file);
    };

    // Exportar como archivo Markdown con frontmatter
    const exportMarkdown = () => {
        try {
            const tagsFormatted = postMetadata.tags
                .split(',')
                .map(tag => tag.trim())
                .filter(tag => tag)
                .join(', ');

            const frontmatter = `---
title: ${postMetadata.title}
excerpt: ${postMetadata.excerpt}
category: ${postMetadata.category}
tags: [${tagsFormatted}]
date: ${new Date().toLocaleDateString('es-CO', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            })}
author: ${postMetadata.author}
coverImage: ${postMetadata.coverImage}
---

${markdownInput}`;

            // Crear y descargar el archivo
            const blob = new Blob([frontmatter], { type: 'text/markdown' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            // Generar nombre de archivo basado en el título
            const filename = postMetadata.title
                .toLowerCase()
                .replace(/[^\w\s]/gi, '')
                .replace(/\s+/g, '-') + '.md';

            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error al exportar el Markdown:', error);
            alert('Error al exportar el archivo. Por favor, inténtalo de nuevo.');
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 py-12">
            <div className="max-w-5xl mx-auto px-4">
                <h1 className="text-3xl font-bold mb-6">Administración del Blog</h1>

                <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
                    {/* Panel izquierdo - Metadatos */}
                    <div className="lg:col-span-2">
                        <div className="bg-white rounded-lg shadow-sm p-6">
                            <h2 className="text-xl font-bold mb-4">Metadatos del Post</h2>
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Título</label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={postMetadata.title}
                                        onChange={handleMetadataChange}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        placeholder="Título del artículo"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Extracto</label>
                                    <textarea
                                        name="excerpt"
                                        value={postMetadata.excerpt}
                                        onChange={handleMetadataChange}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        rows="3"
                                        placeholder="Breve descripción del artículo (aparecerá en la vista previa)"
                                    ></textarea>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Categoría</label>
                                    <select
                                        name="category"
                                        value={postMetadata.category}
                                        onChange={handleMetadataChange}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    >
                                        {categories.map(category => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Tags (separados por comas)</label>
                                    <input
                                        type="text"
                                        name="tags"
                                        value={postMetadata.tags}
                                        onChange={handleMetadataChange}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        placeholder="IA, Transformación Digital, PYMEs"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Autor</label>
                                    <input
                                        type="text"
                                        name="author"
                                        value={postMetadata.author}
                                        onChange={handleMetadataChange}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">Imagen de portada (URL)</label>
                                    <input
                                        type="text"
                                        name="coverImage"
                                        value={postMetadata.coverImage}
                                        onChange={handleMetadataChange}
                                        className="w-full p-2 border border-gray-300 rounded-md"
                                        placeholder="https://ejemplo.com/imagen.jpg"
                                    />
                                </div>

                                <div className="pt-4 space-y-2">
                                    <input
                                        type="file"
                                        accept=".md"
                                        ref={fileInputRef}
                                        onChange={handleFileUpload}
                                        className="hidden"
                                    />
                                    <button
                                        className="w-full px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
                                        onClick={() => fileInputRef.current.click()}
                                    >
                                        Cargar archivo Markdown
                                    </button>

                                    <button
                                        className="w-full px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
                                        onClick={exportMarkdown}
                                    >
                                        Exportar como Markdown
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Panel derecho - Editor y vista previa */}
                    <div className="lg:col-span-3">
                        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
                            <h2 className="text-xl font-bold mb-4">Contenido del Artículo (Markdown)</h2>
                            <textarea
                                value={markdownInput}
                                onChange={(e) => setMarkdownInput(e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-md font-mono text-sm"
                                rows="15"
                                placeholder="Escribe el contenido del artículo en formato Markdown..."
                            ></textarea>

                            <div className="flex justify-end space-x-3 mt-4">
                                <button
                                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
                                    onClick={generatePreview}
                                >
                                    Vista Previa
                                </button>

                                <button
                                    className="px-4 py-2 bg-[#2b3990] text-white rounded-md hover:bg-[#232e73] transition-colors"
                                    onClick={generateJsonOutput}
                                >
                                    Generar JSON
                                </button>
                            </div>
                        </div>

                        {/* Vista previa o salida JSON */}
                        {showPreview && (
                            <div className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-bold mb-4">Vista Previa</h2>
                                <div
                                    className="prose max-w-none"
                                    dangerouslySetInnerHTML={{ __html: previewHtml }}
                                ></div>
                            </div>
                        )}

                        {jsonOutput && (
                            <div className="bg-white rounded-lg shadow-sm p-6">
                                <h2 className="text-xl font-bold mb-4">Código para blogData.js</h2>
                                <div className="bg-gray-100 p-4 rounded-md">
                  <pre className="text-xs sm:text-sm overflow-x-auto">
                    {jsonOutput}
                  </pre>
                                </div>

                                <p className="mt-4 text-sm text-gray-600">
                                    Copia este código y agrégalo al array <code>sampleBlogPosts</code> en el archivo <code>src/data/blogData.js</code>
                                </p>

                                <button
                                    className="mt-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
                                    onClick={() => {
                                        navigator.clipboard.writeText(jsonOutput);
                                        alert('¡JSON copiado al portapapeles!');
                                    }}
                                >
                                    Copiar al portapapeles
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogAdmin;