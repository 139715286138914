// src/pages/admin/ProtectedBlogAdmin.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BlogAdmin from './BlogAdmin';

const ProtectedBlogAdmin = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Contraseña para acceder al admin (cámbiala por una segura)
    const correctPassword = 'peregrino2025';

    // Verificar si ya está autenticado (usando sessionStorage)
    useEffect(() => {
        const authStatus = sessionStorage.getItem('blogAdminAuth');
        if (authStatus === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    const handleLogin = (e) => {
        e.preventDefault();

        if (password === correctPassword) {
            // Guardar estado de autenticación en sessionStorage
            sessionStorage.setItem('blogAdminAuth', 'true');
            setIsAuthenticated(true);
            setError('');
        } else {
            setError('Contraseña incorrecta. Inténtalo de nuevo.');
        }
    };

    const handleLogout = () => {
        // Eliminar estado de autenticación
        sessionStorage.removeItem('blogAdminAuth');
        setIsAuthenticated(false);
        setPassword('');
    };

    // Si está autenticado, mostrar el componente de administración
    if (isAuthenticated) {
        return (
            <div>
                <div className="bg-gray-100 p-4 flex justify-between items-center mb-4">
                    <h2 className="font-semibold text-lg">Panel de Administración del Blog</h2>
                    <button
                        onClick={handleLogout}
                        className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded"
                    >
                        Cerrar Sesión
                    </button>
                </div>
                <BlogAdmin />
            </div>
        );
    }

    // Si no está autenticado, mostrar pantalla de login
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
            <div className="bg-white rounded-lg shadow-md w-full max-w-md p-8">
                <div className="text-center mb-8">
                    <h1 className="text-2xl font-bold text-gray-800">Administración del Blog</h1>
                    <p className="text-gray-600">Ingresa la contraseña para continuar</p>
                </div>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}

                <form onSubmit={handleLogin}>
                    <div className="mb-6">
                        <label
                            htmlFor="password"
                            className="block text-gray-700 text-sm font-semibold mb-2"
                        >
                            Contraseña
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2b3990]"
                            placeholder="Ingresa la contraseña"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-[#2b3990] hover:bg-[#232e73] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors"
                    >
                        Ingresar
                    </button>
                </form>

                <div className="mt-6 text-center">
                    <button
                        onClick={() => navigate('/')}
                        className="text-sm text-[#2b3990] hover:underline"
                    >
                        Volver al sitio principal
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProtectedBlogAdmin;