// src/components/ui/Grid.jsx
import React from 'react';

/**
 * Componente Row para crear filas flexibles responsivas
 * @param {ReactNode} children - Elementos dentro de la fila
 * @param {string} spacing - Espaciado entre elementos (xs, sm, md, lg, xl)
 * @param {string} align - Alineación vertical de los elementos (start, center, end, stretch)
 * @param {string} justify - Justificación horizontal (start, center, end, between, around, evenly)
 * @param {string} className - Clases adicionales para personalización
 */
export const Row = ({
                        children,
                        spacing = 'md',
                        align = 'start',
                        justify = 'start',
                        className = '',
                    }) => {
    const spacingClasses = {
        xs: 'gap-1 sm:gap-2',
        sm: 'gap-2 sm:gap-3',
        md: 'gap-4 sm:gap-6',
        lg: 'gap-6 sm:gap-8',
        xl: 'gap-8 sm:gap-12',
    };

    const alignClasses = {
        start: 'items-start',
        center: 'items-center',
        end: 'items-end',
        stretch: 'items-stretch',
    };

    const justifyClasses = {
        start: 'justify-start',
        center: 'justify-center',
        end: 'justify-end',
        between: 'justify-between',
        around: 'justify-around',
        evenly: 'justify-evenly',
    };

    return (
        <div className={`flex flex-wrap ${spacingClasses[spacing]} ${alignClasses[align]} ${justifyClasses[justify]} ${className}`}>
            {children}
        </div>
    );
};

/**
 * Componente Col para crear columnas flexibles responsivas dentro de una fila
 * @param {ReactNode} children - Elementos dentro de la columna
 * @param {string} span - Ancho de la columna en diferentes tamaños de pantalla (formato: "12 md:6 lg:4")
 * @param {string} className - Clases adicionales para personalización
 */
export const Col = ({
                        children,
                        span = '',
                        className = ''
                    }) => {
    // Si no se proporciona span, usar clases predeterminadas responsivas
    const spanClasses = span || 'w-full md:w-1/2 lg:w-1/3';

    return (
        <div className={`${spanClasses} ${className}`}>
            {children}
        </div>
    );
};

/**
 * Componente Grid para crear diseños de cuadrícula responsivos
 * @param {ReactNode} children - Elementos dentro del grid
 * @param {string} columns - Número de columnas en diferentes breakpoints (formato: "1 md:2 lg:3")
 * @param {string} spacing - Espaciado entre elementos (xs, sm, md, lg, xl)
 * @param {string} className - Clases adicionales para personalización
 */
export const Grid = ({
                         children,
                         columns = '1 md:2 lg:3',
                         spacing = 'md',
                         className = ''
                     }) => {
    const spacingClasses = {
        xs: 'gap-1 sm:gap-2',
        sm: 'gap-2 sm:gap-3',
        md: 'gap-4 sm:gap-6',
        lg: 'gap-6 sm:gap-8',
        xl: 'gap-8 sm:gap-12',
    };

    // Convertir el formato de columnas a clases de grid de Tailwind
    const gridClasses = `grid ${columns.split(' ').map(col => {
        // Comprobar si el valor incluye un breakpoint
        if (col.includes(':')) {
            const [breakpoint, value] = col.split(':');
            return `${breakpoint}:grid-cols-${value}`;
        }
        // Si no hay breakpoint, es el valor base
        return `grid-cols-${col}`;
    }).join(' ')} ${spacingClasses[spacing]}`;

    return (
        <div className={`${gridClasses} ${className}`}>
            {children}
        </div>
    );
};

export default { Row, Col, Grid };