// src/pages/About.jsx
import React from 'react';
import { Globe, Users, Award, Target, ArrowRight, ExternalLink } from 'lucide-react';
import logoPeregrino from '../assets/images/peregrino-logo-black.png';

const About = () => {
    return (
        <div className="w-full bg-white">
            {/* Hero Banner */}
            <section className="w-full bg-[#2b3990] py-16">
                <div className="max-w-7xl mx-auto px-6">
                    <h1 className="text-4xl md:text-5xl font-bold text-white uppercase text-center">
                        NOSOTROS
                    </h1>
                    <p className="text-xl text-white/80 text-center max-w-3xl mx-auto mt-4">
                        Combinamos experiencia global y soluciones tecnológicas para transformar negocios en la era digital
                    </p>
                </div>
            </section>

            {/* Nuestra Historia */}
            <section className="w-full py-16">
                <div className="max-w-7xl mx-auto px-6">
                    <div className="flex flex-col md:flex-row gap-12 items-center">
                        <div className="md:w-1/2">
                            <h2 className="text-3xl font-bold text-[#2b3990] mb-6">Nuestra Historia</h2>
                            <p className="text-gray-700 mb-4">
                                Peregrino nace de la visión de transformar la manera en que las empresas adoptan y aprovechan la tecnología. Fundada por Rodrigo De Angulo, nuestra consultora combina décadas de experiencia en comercio electrónico, transformación digital y estrategia de producto con un enfoque práctico y orientado a resultados.
                            </p>
                            <p className="text-gray-700 mb-4">
                                El nombre "Peregrino" representa nuestra filosofía: somos viajeros en búsqueda constante de conocimiento y mejora. Acompañamos a nuestros clientes en su propio viaje de transformación, explorando nuevos territorios digitales y descubriendo oportunidades que generan valor real.
                            </p>
                            <p className="text-gray-700">
                                Hoy, nuestra red de profesionales especializados opera en múltiples países de Latinoamérica, sirviendo a clientes que van desde startups disruptivas hasta corporaciones establecidas en diversos sectores.
                            </p>
                        </div>
                        <div className="md:w-1/2 flex justify-center">
                            <div className="relative">
                                <div className="w-64 h-64 md:w-80 md:h-80 bg-[#2b3990]/10 rounded-full flex items-center justify-center">
                                    <img
                                        src={logoPeregrino}
                                        alt="Peregrino Logo"
                                        className="w-48 md:w-64"
                                    />
                                </div>
                                <div className="absolute -bottom-4 -right-4 bg-[#2b3990] text-white px-6 py-3 rounded-lg shadow-lg">
                                    <p className="font-medium">Fundada en 2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Nuestros Valores */}
            <section className="w-full py-16 bg-gray-50">
                <div className="max-w-7xl mx-auto px-6">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold text-[#2b3990] mb-4">
                            Nuestros Valores
                        </h2>
                        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                            Principios que guían cada proyecto y relación que establecemos
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                            <div className="w-12 h-12 rounded-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990] mb-4">
                                <Target className="w-6 h-6" />
                            </div>
                            <h3 className="text-xl font-semibold mb-3">Resultados Tangibles</h3>
                            <p className="text-gray-600">
                                Nos enfocamos en generar impacto medible y valor real para nuestros clientes desde las primeras etapas de cada proyecto.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                            <div className="w-12 h-12 rounded-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990] mb-4">
                                <Award className="w-6 h-6" />
                            </div>
                            <h3 className="text-xl font-semibold mb-3">Excelencia Técnica</h3>
                            <p className="text-gray-600">
                                Mantenemos los más altos estándares de calidad en cada solución que diseñamos e implementamos.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                            <div className="w-12 h-12 rounded-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990] mb-4">
                                <Users className="w-6 h-6" />
                            </div>
                            <h3 className="text-xl font-semibold mb-3">Colaboración</h3>
                            <p className="text-gray-600">
                                Trabajamos como una extensión del equipo de nuestros clientes, aportando nuestra experiencia mientras aprovechamos su conocimiento del negocio.
                            </p>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                            <div className="w-12 h-12 rounded-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990] mb-4">
                                <Globe className="w-6 h-6" />
                            </div>
                            <h3 className="text-xl font-semibold mb-3">Perspectiva Global</h3>
                            <p className="text-gray-600">
                                Aplicamos conocimientos y mejores prácticas internacionales adaptadas a las realidades y necesidades locales.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Equipo Fundador */}
            <section className="w-full py-16">
                <div className="max-w-7xl mx-auto px-6">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold text-[#2b3990] mb-4">
                            Liderazgo
                        </h2>
                        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                            Experiencia y visión que impulsan nuestra misión
                        </p>
                    </div>

                    <div className="max-w-3xl mx-auto">
                        <div className="bg-white rounded-xl overflow-hidden shadow-md">
                            <div className="p-8">
                                <div className="flex flex-col md:flex-row gap-8 items-center md:items-start">
                                    <div className="w-48 h-48 rounded-full bg-gray-100 overflow-hidden flex-shrink-0">
                                        {/* Aquí iría la foto de Rodrigo, por ahora usamos un placeholder */}
                                        <div className="w-full h-full bg-[#2b3990]/10 flex items-center justify-center text-[#2b3990]">
                                            <span className="text-7xl font-bold">R</span>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="text-2xl font-bold mb-2">Rodrigo De Angulo</h3>
                                        <p className="text-[#2b3990] font-medium mb-4">Fundador & CEO</p>
                                        <p className="text-gray-700 mb-4">
                                            Líder con amplia experiencia en negocios digitales, comercio electrónico y transformación tecnológica. Ha liderado iniciativas de digitalización en empresas como Otter, Aval Digital Labs, Falabella.com, y Walgreens Boots Alliance.
                                        </p>
                                        <p className="text-gray-700 mb-4">
                                            Especialista en la conexión entre tecnología y estrategia de negocio, Rodrigo ha dirigido equipos en transiciones digitales, localización de productos y expansión impulsada por tecnología en mercados globales.
                                        </p>
                                        <div className="flex flex-wrap gap-2 mt-6">
                                            <span className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">Comercio Electrónico</span>
                                            <span className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">Transformación Digital</span>
                                            <span className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">Estrategia de Producto</span>
                                            <span className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm">Inteligencia Artificial</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Red Global */}
            <section className="w-full py-16 bg-[#2b3990]">
                <div className="max-w-7xl mx-auto px-6">
                    <div className="flex flex-col md:flex-row gap-12 items-center">
                        <div className="md:w-1/2">
                            <h2 className="text-3xl font-bold text-white mb-6">Red Global de Profesionales</h2>
                            <p className="text-white/90 mb-4">
                                Contamos con una extensa red de profesionales especializados en tecnología, diseño, desarrollo de software, análisis de datos e inteligencia artificial que nos permite abordar proyectos complejos en múltiples países.
                            </p>
                            <p className="text-white/90 mb-6">
                                Nuestra red se extiende por toda Latinoamérica y trabajamos con socios estratégicos en Estados Unidos y Europa, permitiéndonos ofrecer soluciones con una perspectiva global y una ejecución local.
                            </p>
                            <div className="flex flex-wrap gap-4">
                                <div className="bg-white/10 backdrop-blur-sm px-4 py-2 rounded-lg">
                                    <p className="text-white font-medium text-lg">10+</p>
                                    <p className="text-white/70 text-sm">Países</p>
                                </div>
                                <div className="bg-white/10 backdrop-blur-sm px-4 py-2 rounded-lg">
                                    <p className="text-white font-medium text-lg">30+</p>
                                    <p className="text-white/70 text-sm">Profesionales</p>
                                </div>
                                <div className="bg-white/10 backdrop-blur-sm px-4 py-2 rounded-lg">
                                    <p className="text-white font-medium text-lg">15+</p>
                                    <p className="text-white/70 text-sm">Especialidades</p>
                                </div>
                                <div className="bg-white/10 backdrop-blur-sm px-4 py-2 rounded-lg">
                                    <p className="text-white font-medium text-lg">50+</p>
                                    <p className="text-white/70 text-sm">Proyectos</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2">
                            <div className="relative">
                                {/* World map illustration or network graphic would go here */}
                                <div className="bg-white/5 backdrop-blur-sm rounded-xl p-8 border border-white/10">
                                    <h3 className="text-white font-bold text-xl mb-4">Países donde operamos</h3>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">Colombia</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">México</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">Chile</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">Perú</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">Argentina</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">Brasil</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">Ecuador</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">Panamá</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">Estados Unidos</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <div className="w-2 h-2 rounded-full bg-white"></div>
                                            <span className="text-white/90">España</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA */}
            <section className="w-full py-16 bg-gray-50">
                <div className="max-w-3xl mx-auto px-6 text-center">
                    <h2 className="text-3xl font-bold text-[#2b3990] mb-6">
                        Trabaja con nosotros
                    </h2>
                    <p className="text-lg text-gray-600 mb-8">
                        Descubre cómo podemos ayudarte a transformar digitalmente tu negocio con soluciones a medida y resultados tangibles.
                    </p>
                    <a
                        href="mailto:rodrigo@peregrino.co"
                        className="inline-flex items-center justify-center px-8 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#2b3990] hover:bg-[#232e73] transition-colors"
                    >
                        Contáctanos hoy <ArrowRight className="ml-2 h-5 w-5" />
                    </a>
                </div>
            </section>
        </div>
    );
};

export default About;