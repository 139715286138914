// src/pages/HowWeDoIt.jsx
import React from 'react';
import {
    Lightbulb, Rocket, Users, ArrowUpRight,
    BrainCircuit, Sparkles, LineChart, MessageCircle, Bot,
    BarChart3, Network, Workflow, CheckCircle, Lock, Search, Filter
} from 'lucide-react';

function HowWeDoIt() {
    return (
        <div className="w-full min-h-screen">
            {/* Hero Banner */}
            <section className="w-full bg-peregrino-blue py-16">
                <div className="max-w-[1400px] mx-auto px-6">
                    <h1 className="text-4xl md:text-5xl font-bold text-white uppercase text-center">
                        CÓMO LO HACEMOS
                    </h1>
                    <p className="text-xl text-white/80 text-center max-w-3xl mx-auto mt-4">
                        Combinamos experiencia humana con inteligencia artificial para crear soluciones que generan resultados tangibles
                    </p>
                </div>
            </section>

            {/* Process and Approach Two-Column Section */}
            <section className="w-full py-16 bg-white">
                <div className="max-w-[1200px] mx-auto px-6">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold uppercase mb-4">
                            NUESTRA METODOLOGÍA
                        </h2>
                        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                            Combinamos un proceso estructurado con un enfoque estratégico para garantizar resultados tangibles
                        </p>
                    </div>

                    {/* Two-column layout */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {/* Left Column - Process */}
                        <div>
                            <h3 className="text-2xl font-bold uppercase mb-6 text-peregrino-blue">
                                NUESTRO PROCESO
                            </h3>

                            {/* Funnel Visualization */}
                            <div className="relative mb-8">
                                <div className="absolute left-0 right-0 top-0 bottom-0 bg-gradient-to-b from-peregrino-blue/5 to-peregrino-blue/10 rounded-xl -z-10"></div>

                                {/* Funnel Step 1 */}
                                <div className="relative">
                                    <div className="bg-white rounded-xl p-6 shadow-md mb-4 border-l-4 border-peregrino-blue transform transition-transform hover:scale-[1.02]">
                                        <div className="flex items-start gap-4">
                                            <div className="w-12 h-12 rounded-full bg-peregrino-blue/10
                            flex items-center justify-center flex-shrink-0">
                                                <Lightbulb className="w-6 h-6 text-peregrino-blue" />
                                            </div>
                                            <div>
                                                <h3 className="text-xl uppercase mb-2">
                                                    DIAGNÓSTICO
                                                </h3>
                                                <p className="text-gray-600">
                                                    Analizamos en profundidad las necesidades, oportunidades y
                                                    desafíos específicos de tu negocio.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="h-6 w-6 mx-auto">
                                        <div className="w-0 h-0 border-l-[15px] border-l-transparent border-t-[15px] border-t-peregrino-blue/20 border-r-[15px] border-r-transparent mx-auto"></div>
                                    </div>
                                </div>

                                {/* Funnel Step 2 */}
                                <div className="relative">
                                    <div className="bg-white rounded-xl p-6 shadow-md mb-4 border-l-4 border-peregrino-blue transform transition-transform hover:scale-[1.02]">
                                        <div className="flex items-start gap-4">
                                            <div className="w-12 h-12 rounded-full bg-peregrino-blue/10
                            flex items-center justify-center flex-shrink-0">
                                                <Workflow className="w-6 h-6 text-peregrino-blue" />
                                            </div>
                                            <div>
                                                <h3 className="text-xl uppercase mb-2">
                                                    DISEÑO
                                                </h3>
                                                <p className="text-gray-600">
                                                    Creamos arquitecturas tecnológicas adaptadas a tus necesidades específicas.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="h-6 w-6 mx-auto">
                                        <div className="w-0 h-0 border-l-[15px] border-l-transparent border-t-[15px] border-t-peregrino-blue/20 border-r-[15px] border-r-transparent mx-auto"></div>
                                    </div>
                                </div>

                                {/* Funnel Step 3 */}
                                <div className="relative">
                                    <div className="bg-white rounded-xl p-6 shadow-md mb-4 border-l-4 border-peregrino-blue transform transition-transform hover:scale-[1.02]">
                                        <div className="flex items-start gap-4">
                                            <div className="w-12 h-12 rounded-full bg-peregrino-blue/10
                            flex items-center justify-center flex-shrink-0">
                                                <Rocket className="w-6 h-6 text-peregrino-blue" />
                                            </div>
                                            <div>
                                                <h3 className="text-xl uppercase mb-2">
                                                    IMPLEMENTACIÓN
                                                </h3>
                                                <p className="text-gray-600">
                                                    Desarrollo ágil con resultados tempranos y medibles.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="h-6 w-6 mx-auto">
                                        <div className="w-0 h-0 border-l-[15px] border-l-transparent border-t-[15px] border-t-peregrino-blue/20 border-r-[15px] border-r-transparent mx-auto"></div>
                                    </div>
                                </div>

                                {/* Funnel Step 4 */}
                                <div className="relative">
                                    <div className="bg-white rounded-xl p-6 shadow-md border-l-4 border-peregrino-blue transform transition-transform hover:scale-[1.02]">
                                        <div className="flex items-start gap-4">
                                            <div className="w-12 h-12 rounded-full bg-peregrino-blue/10
                            flex items-center justify-center flex-shrink-0">
                                                <Users className="w-6 h-6 text-peregrino-blue" />
                                            </div>
                                            <div>
                                                <h3 className="text-xl uppercase mb-2">
                                                    ACOMPAÑAMIENTO
                                                </h3>
                                                <p className="text-gray-600">
                                                    Soporte continuo y optimización constante.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Column - Our Approach */}
                        <div>
                            <h3 className="text-2xl font-bold uppercase mb-6 text-peregrino-blue">
                                NUESTRO ENFOQUE
                            </h3>
                            <div className="bg-peregrino-blue/5 rounded-xl p-6 border border-peregrino-blue/20 h-full">
                                <div className="max-w-none">
                                    <p className="mb-6">
                                        En Peregrino, no solo aplicamos tecnología, construimos soluciones que generan
                                        resultados tangibles para tu negocio. Nuestro enfoque se basa en tres pilares
                                        fundamentales:
                                    </p>

                                    <div className="mb-6">
                                        <h4 className="text-lg uppercase flex items-center gap-2">
                                            <div className="w-8 h-8 rounded-full bg-peregrino-blue/10
                                            flex items-center justify-center flex-shrink-0">
                                                <LineChart className="w-4 h-4 text-peregrino-blue" />
                                            </div>
                                            ESTRATEGIA QUE VENDE
                                        </h4>
                                        <p className="mt-2 text-gray-600">
                                            Cada solución que implementamos está diseñada para impactar directamente en tus
                                            resultados comerciales. No creemos en tecnología por tecnología, sino en
                                            herramientas que potencien tu propuesta de valor.
                                        </p>
                                    </div>

                                    <div className="mb-6">
                                        <h4 className="text-lg uppercase flex items-center gap-2">
                                            <div className="w-8 h-8 rounded-full bg-peregrino-blue/10
                                            flex items-center justify-center flex-shrink-0">
                                                <Sparkles className="w-4 h-4 text-peregrino-blue" />
                                            </div>
                                            TECNOLOGÍA QUE ESCALA
                                        </h4>
                                        <p className="mt-2 text-gray-600">
                                            Desarrollamos soluciones pensadas para crecer con tu negocio. Nuestras implementaciones
                                            son modulares, adaptables y preparadas para evolucionar según tus necesidades futuras.
                                        </p>
                                    </div>

                                    <div>
                                        <h4 className="text-lg uppercase flex items-center gap-2">
                                            <div className="w-8 h-8 rounded-full bg-peregrino-blue/10
                                            flex items-center justify-center flex-shrink-0">
                                                <BarChart3 className="w-4 h-4 text-peregrino-blue" />
                                            </div>
                                            RESULTADOS QUE TRANSFORMAN
                                        </h4>
                                        <p className="mt-2 text-gray-600">
                                            Medimos nuestro éxito en función de los resultados que generamos para tu empresa.
                                            Establecemos KPIs claros desde el inicio y trabajamos para superarlos, asegurando
                                            un retorno tangible de tu inversión.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Experience and Approach Section */}
            <section className="w-full py-16 bg-gray-50">
                <div className="max-w-[1200px] mx-auto px-6">
                    <div className="text-center mb-16">
                        <h2 className="text-4xl font-bold uppercase mb-4">
                            NUESTRA EXPERIENCIA
                        </h2>
                        <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                            Más allá de la tecnología, aportamos un enfoque multidisciplinario y una experiencia diversa
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
                        {/* Experience in Multiple Industries */}
                        <div className="bg-white rounded-xl shadow-md p-8 hover:shadow-lg transition-shadow border-t-4 border-peregrino-blue">
                            <div className="flex mb-6 items-center gap-4">
                                <div className="w-12 h-12 rounded-full bg-peregrino-blue/10
                  flex items-center justify-center flex-shrink-0">
                                    <Network className="w-6 h-6 text-peregrino-blue" />
                                </div>
                                <h3 className="text-xl uppercase">
                                    EXPERIENCIA MULTISECTORIAL
                                </h3>
                            </div>

                            <p className="text-gray-600 mb-6">
                                Nuestra experiencia en múltiples industrias nos permite hacer las preguntas
                                correctas y entender las particularidades de cada sector. Hemos trabajado con:
                            </p>

                            <ul className="grid grid-cols-2 gap-3">
                                <li className="flex items-center gap-2">
                                    <CheckCircle className="text-peregrino-blue w-4 h-4 flex-shrink-0" />
                                    <span>Sector inmobiliario</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <CheckCircle className="text-peregrino-blue w-4 h-4 flex-shrink-0" />
                                    <span>Comercio electrónico</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <CheckCircle className="text-peregrino-blue w-4 h-4 flex-shrink-0" />
                                    <span>Salud y bienestar</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <CheckCircle className="text-peregrino-blue w-4 h-4 flex-shrink-0" />
                                    <span>Logística y distribución</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <CheckCircle className="text-peregrino-blue w-4 h-4 flex-shrink-0" />
                                    <span>Servicios financieros</span>
                                </li>
                                <li className="flex items-center gap-2">
                                    <CheckCircle className="text-peregrino-blue w-4 h-4 flex-shrink-0" />
                                    <span>Manufactura</span>
                                </li>
                            </ul>
                        </div>

                        {/* Conscious of Biases */}
                        <div className="bg-white rounded-xl shadow-md p-8 hover:shadow-lg transition-shadow border-t-4 border-peregrino-blue">
                            <div className="flex mb-6 items-center gap-4">
                                <div className="w-12 h-12 rounded-full bg-peregrino-blue/10
                  flex items-center justify-center flex-shrink-0">
                                    <Filter className="w-6 h-6 text-peregrino-blue" />
                                </div>
                                <h3 className="text-xl uppercase">
                                    CONCIENCIA DE SESGOS
                                </h3>
                            </div>

                            <p className="text-gray-600 mb-6">
                                Somos conscientes de nuestros posibles sesgos y trabajamos activamente para superarlos.
                                Nuestro enfoque incluye:
                            </p>

                            <ul className="space-y-4">
                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Diversidad de perspectivas:</span>
                                        <p className="text-gray-600 mt-1">
                                            Incorporamos diferentes puntos de vista para enriquecer nuestras soluciones
                                            y evitar visiones unilaterales.
                                        </p>
                                    </div>
                                </li>

                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Aprendizaje constante:</span>
                                        <p className="text-gray-600 mt-1">
                                            No tememos equivocarnos. Cada proyecto es una oportunidad para aprender
                                            y mejorar nuestro enfoque.
                                        </p>
                                    </div>
                                </li>

                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Validación continua:</span>
                                        <p className="text-gray-600 mt-1">
                                            Contrastamos nuestras hipótesis con datos reales para asegurar que las
                                            soluciones respondan a necesidades concretas, no a suposiciones.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {/* Technology Architecture */}
                        <div className="bg-white rounded-xl shadow-md p-8 hover:shadow-lg transition-shadow border-t-4 border-peregrino-blue">
                            <div className="flex mb-6 items-center gap-4">
                                <div className="w-12 h-12 rounded-full bg-peregrino-blue/10
                  flex items-center justify-center flex-shrink-0">
                                    <BrainCircuit className="w-6 h-6 text-peregrino-blue" />
                                </div>
                                <h3 className="text-xl uppercase">
                                    ARQUITECTURA TECNOLÓGICA
                                </h3>
                            </div>

                            <p className="text-gray-600 mb-6">
                                Entendemos a profundidad la arquitectura tecnológica y hacemos las preguntas
                                adecuadas para diseñar soluciones óptimas:
                            </p>

                            <ul className="space-y-4">
                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Evaluación de infraestructura:</span>
                                        <p className="text-gray-600 mt-1">
                                            Analizamos tu ecosistema tecnológico actual para identificar puntos de
                                            integración y oportunidades de mejora.
                                        </p>
                                    </div>
                                </li>

                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Diseño adaptado:</span>
                                        <p className="text-gray-600 mt-1">
                                            Creamos arquitecturas que se ajustan a tu realidad actual, pero preparadas
                                            para evolucionar con tu negocio.
                                        </p>
                                    </div>
                                </li>

                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Preguntas estratégicas:</span>
                                        <p className="text-gray-600 mt-1">
                                            Formulamos las preguntas correctas para entender no solo tus necesidades
                                            técnicas, sino también tus objetivos de negocio.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {/* AI Implementation */}
                        <div className="bg-white rounded-xl shadow-md p-8 hover:shadow-lg transition-shadow border-t-4 border-peregrino-blue">
                            <div className="flex mb-6 items-center gap-4">
                                <div className="w-12 h-12 rounded-full bg-peregrino-blue/10
                  flex items-center justify-center flex-shrink-0">
                                    <Bot className="w-6 h-6 text-peregrino-blue" />
                                </div>
                                <h3 className="text-xl uppercase">
                                    IMPLEMENTACIÓN DE IA
                                </h3>
                            </div>

                            <p className="text-gray-600 mb-6">
                                Implementamos IA de manera práctica y orientada a resultados:
                            </p>

                            <ul className="space-y-4">
                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Asistentes virtuales:</span>
                                        <p className="text-gray-600 mt-1">
                                            Automatizamos la atención al cliente y procesos internos con asistentes
                                            virtuales que resuelven consultas 24/7.
                                        </p>
                                    </div>
                                </li>

                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Personalización:</span>
                                        <p className="text-gray-600 mt-1">
                                            Utilizamos IA para crear experiencias personalizadas que incrementan
                                            la conversión y fidelización.
                                        </p>
                                    </div>
                                </li>

                                <li className="flex items-start gap-3">
                                    <CheckCircle className="text-peregrino-blue w-5 h-5 mt-1 flex-shrink-0" />
                                    <div>
                                        <span className="font-semibold">Optimización de procesos:</span>
                                        <p className="text-gray-600 mt-1">
                                            Aplicamos inteligencia artificial para automatizar tareas repetitivas
                                            y optimizar flujos de trabajo.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Case Example */}
                    <div className="bg-peregrino-blue rounded-xl p-10 text-white shadow-xl">
                        <div className="flex flex-col md:flex-row items-start gap-8">
                            <div className="md:w-1/3">
                                <h2 className="text-3xl uppercase mb-4">
                                    CASO DE ÉXITO
                                </h2>
                                <div className="w-20 h-1 bg-white mb-6"></div>
                                <div className="flex justify-center">
                                    <Bot className="w-32 h-32 opacity-80" />
                                </div>
                            </div>

                            <div className="md:w-2/3">
                                <h3 className="text-2xl uppercase mb-4">
                                    ASISTENTE VIRTUAL PARA UNA CONSTRUCTORA
                                </h3>
                                <p className="mb-6 text-white/90">
                                    Implementamos un asistente virtual impulsado por IA que transformó el proceso de ventas
                                    y atención al cliente para esta empresa inmobiliaria:
                                </p>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                                    <div>
                                        <h4 className="text-xl mb-2">RESULTADOS:</h4>
                                        <ul className="space-y-2">
                                            <li className="flex items-center gap-2">
                                                <CheckCircle className="w-5 h-5 text-white flex-shrink-0" />
                                                <span>Reducción del 70% en tiempo de respuesta</span>
                                            </li>
                                            <li className="flex items-center gap-2">
                                                <CheckCircle className="w-5 h-5 text-white flex-shrink-0" />
                                                <span>Incremento del 40% en tasa de conversión</span>
                                            </li>
                                            <li className="flex items-center gap-2">
                                                <CheckCircle className="w-5 h-5 text-white flex-shrink-0" />
                                                <span>Atención 24/7 sin incrementar personal</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div>
                                        <h4 className="text-xl mb-2">FUNCIONALIDADES:</h4>
                                        <ul className="space-y-2">
                                            <li className="flex items-center gap-2">
                                                <CheckCircle className="w-5 h-5 text-white flex-shrink-0" />
                                                <span>Calificación automática de leads</span>
                                            </li>
                                            <li className="flex items-center gap-2">
                                                <CheckCircle className="w-5 h-5 text-white flex-shrink-0" />
                                                <span>Cotizaciones personalizadas instantáneas</span>
                                            </li>
                                            <li className="flex items-center gap-2">
                                                <CheckCircle className="w-5 h-5 text-white flex-shrink-0" />
                                                <span>Seguimiento automatizado de clientes</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <button className="mt-4 bg-white text-peregrino-blue px-6 py-3 rounded-lg
                font-semibold hover:bg-white/90 transition-all inline-flex items-center gap-2">
                                    VER CASO COMPLETO <ArrowUpRight />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="w-full bg-peregrino-blue py-16">
                <div className="max-w-[800px] mx-auto px-6 text-center">
                    <h2 className="text-3xl font-bold mb-6 uppercase text-white">
                        COMIENZA TU TRANSFORMACIÓN DIGITAL HOY
                    </h2>
                    <p className="text-xl text-white/90 mb-8 max-w-2xl mx-auto">
                        Agenda una consultoría inicial sin compromiso y descubre
                        el potencial de tu negocio con soluciones personalizadas.
                    </p>
                    <a
                        href="mailto:rodrigo@peregrino.co"
                        className="bg-white text-peregrino-blue px-8 py-3 rounded-lg
                        font-semibold hover:bg-white/90 transition-all inline-flex items-center gap-2"
                    >
                        CONTACTAR AHORA
                    </a>
                </div>
            </section>
        </div>
    );
}

export default HowWeDoIt;