// src/components/ui/Button.jsx
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Componente Button responsivo para acciones interactivas
 * @param {ReactNode} children - Contenido del botón
 * @param {string} variant - Variante visual (primary, secondary, outline, text)
 * @param {string} size - Tamaño del botón (sm, md, lg)
 * @param {string} href - URL para enlaces internos (usando React Router)
 * @param {string} to - URL para enlaces externos
 * @param {boolean} fullWidth - Si el botón debe ocupar todo el ancho disponible
 * @param {string} className - Clases adicionales para personalización
 * @param {Object} props - Propiedades adicionales para el elemento button o a
 */
const Button = ({
                    children,
                    variant = 'primary',
                    size = 'md',
                    href = null,
                    to = null,
                    fullWidth = false,
                    className = '',
                    ...props
                }) => {
    const variantClasses = {
        primary: 'bg-[#2b3990] text-white hover:bg-[#232e73]',
        secondary: 'bg-gray-200 text-gray-800 hover:bg-gray-300',
        outline: 'bg-transparent border border-[#2b3990] text-[#2b3990] hover:bg-[#2b3990]/5',
        text: 'bg-transparent text-[#2b3990] hover:bg-[#2b3990]/5',
    };

    const sizeClasses = {
        sm: 'text-xs sm:text-sm px-3 sm:px-4 py-1.5 sm:py-2',
        md: 'text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-2.5',
        lg: 'text-base sm:text-lg px-5 sm:px-8 py-2.5 sm:py-3',
    };

    const widthClass = fullWidth ? 'w-full' : '';

    const baseClasses = `inline-flex items-center justify-center font-medium rounded-md shadow-sm transition-colors ${widthClass} ${variantClasses[variant]} ${sizeClasses[size]} ${className}`;

    // Si se proporciona href, renderizar un Link de React Router
    if (to) {
        return (
            <Link to={to} className={baseClasses} {...props}>
                {children}
            </Link>
        );
    }

    // Si se proporciona to, renderizar un enlace externo
    if (href) {
        return (
            <a href={href} className={baseClasses} {...props}>
                {children}
            </a>
        );
    }

    // De lo contrario, renderizar un botón normal
    return (
        <button className={baseClasses} {...props}>
            {children}
        </button>
    );
};

export default Button;